import React from 'react';

export const getInputClasses = (fieldId, isActive, textErrors, fieldErrors) => {
  let classes = 'GenerateAiTourForm__input ';

  if (isActive) {
    classes += ' GenerateAiTourForm__input_active';
  }

  const hasError =
    fieldId === 'stopsInput' ||
    fieldId === 'storiesInput' ||
    fieldId === 'durationInput' ||
    fieldId === 'routeInput'
      ? React.isValidElement(textErrors[fieldId.replace('Input', '')])
      : fieldErrors[fieldId] ?? false;

  if (hasError) {
    classes += ' GenerateAiTourForm__input--error';
  }

  return classes;
};
