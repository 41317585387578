import React, { useRef, Fragment, useEffect, useState } from 'react';
import get from 'lodash/get';
import makeStyles from '@material-ui/styles/makeStyles';
// eslint-disable-next-line import/no-cycle
import { APP_HEADER_HEIGHT } from '../../App';
import useStateRef from '../../../functions/react/useStateRef';
import useContinuousEvent from '../../../functions/event/useContinuousEvent';
import Table from '..';

const HEADER_MARGIN = -30;

/**
 * JSS styles for `Table::withStickyHeader` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    top: (props) => (props.stickyTop ? props.stickyTop : APP_HEADER_HEIGHT),
    marginRight: `${HEADER_MARGIN}px`,
    background: theme.palette.background.main,
    zIndex: '1',
  },
}));

/**
 * Adds filters to `Table::withOptions(Table)` component
 * @param {React::Component} Component - overloaded `Table` component
 */
export default function withStickyHeader(Component) {
  /**
   * `Table` component with sticky header
   * @param {Object} $
   * @param {String} $.stickyTop - offset of the header of the table in "sticked" mode (`top` CSS property value for sticky-positioned element)
   * @param {TableHeaderData} $.header - table header description (will be copied and overloaded)
   * @param {...Object} $.tableProps - will be passed with no mutations to `Table` component
   */
  return function TableStickyHeader({ header: headerExt, ...tableProps }) {
    const styles = useStyles();

    /**
     * Ref to the root element of `Table` component containing table header
     * @type {React::Ref}
     */
    const headerRootRef = useRef();

    /**
     * Node `<table />` element containing table header
     * @type {HTMLTableElement}
     */
    const [headerNode, setHeaderNode] = useState();

    /**
     * Ref to the root element of `Table` component containing table body
     * @type {React::Ref}
     */
    const tableRootRef = useRef();

    /**
     * Node `<table />` element containing table body
     * @type {HTMLTableElement}
     */
    const [tableNode, setTableNode] = useState();

    /**
     * Trigger for force updating component
     * @type {Number}
     */
    const [triggerRef, setTrigger] = useStateRef(0).slice(1);

    /**
     * Gets widths of the cells from table linked through `targetRef`
     * @param {HTMLTableElement} targetNode - ref to the table from which cell widths will be copied
     */
    const updateCellWidths = (targetNode) => {
      return Array.from(get(targetNode, 'rows.0.cells', [])).map(
        (cell) => cell.clientWidth
      );
    };

    const headerCellWidths = updateCellWidths(headerNode);
    const tableCellWidths = updateCellWidths(tableNode);

    /**
     * Setting current widths to the table columns
     */
    const header =
      tableNode && headerNode
        ? headerExt.map((item, i) => {
            const maxCellWidth = Math.max(
              headerCellWidths[i],
              tableCellWidths[i]
            );
            return {
              ...item,
              attributes: {
                style: {
                  minWidth: `${maxCellWidth}px`,
                },
              },
            };
          })
        : headerExt;

    /**
     * Ref storing `updateCellWidths` for `table` cells
     * To have an ability for removing it from listeners array
     * @type {React::Ref<Function>}
     */
    const updateCellWidthsRef = useContinuousEvent(
      () => {},
      () => setTrigger((triggerRef.current + 1) % 100)
    )[0];

    /**
     * Updating header cell widths when windows changes it's size
     */
    useEffect(() => {
      const onResized = updateCellWidthsRef.current;
      window.addEventListener('resize', onResized);
      return () => window.removeEventListener('resize', onResized);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Scrolls element linked via `slaveRef` synchronously with `event.target` element
     * @param {React::Ref} slaveRef - ref to element which must be scrolled synchronously with `event.target`
     * @param {ScrollEvent} event - event of user scrolling process
     */
    const scrollSynchronously = useContinuousEvent(
      (event) => ['Left', 'Top'].map((t) => event.target[`scroll${t}`]),
      (coords, slaveRef) => {
        if (slaveRef.current) {
          slaveRef.current.scrollTo(...coords);
        }
      }
    )[0].current;

    return (
      <Fragment>
        <Table
          className={styles.header}
          rootRef={headerRootRef}
          tableRef={(node) => setHeaderNode(node)}
          header={header}
          onScrolled={(e) => scrollSynchronously(e, tableRootRef)}
        />
        <Component
          {...tableProps}
          showHeader={false}
          rootRef={tableRootRef}
          tableRef={(node) => setTableNode(node)}
          header={header}
          onScrolled={(e) => scrollSynchronously(e, headerRootRef)}
        />
      </Fragment>
    );
  };
}
