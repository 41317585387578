/* eslint-disable no-undef */
/**
 * Wrapper for analytics sending interface
 * @param {String} method - which method of analytics to use, "track" by default
 * @param {String} [eventName] - optional event name to track
 * @param {Object} params - will be passed into interface
 */
export default function sendEvent(method, eventName, params = {}) {
  try {
    if (typeof eventName === 'string') {
      analytics[method](eventName, params);
    } else {
      analytics[method](eventName);
    }
  } catch (err) {
    console.log(
      `Error in analytics with method ${method}`,
      { eventName, params },
      err
    );
  }
}
