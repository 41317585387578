/**
 * Generates `TableRowData` instances from `TableHeaderData::id` ids
 * @param {Array[TableHeaderData]} header - table header description
 * @param {Array} data - raw table data (only array size matters)
 * @param {Function} callback - calls with current `TableHeaderData::id` and generated row raw data `data[i]`
 * @param {Boolean} isCommonId - if set, second parameter of the callback wil be `data[i][TableHeaderData::id]`
 * @return {Array[TableRowData]}
 */
export default function rowsFromHeader(
  header,
  data,
  callback = () => {},
  isCommonId = false
) {
  return data.map((row) =>
    header.reduce(
      (acc, { id }) => ({
        ...acc,
        [id]: callback(id, isCommonId ? row[id] : row, row),
      }),
      {}
    )
  );
}
