/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../Icon';
import Spinner from '../../Spinner';
import { useOutsideAlerter } from '../useOutsideAlerter';

export const DropdownInput = ({
  id,
  label,
  value,
  options,
  onSelect,
  activeDropdownId,
  onToggleDropdown,
  disabledOption,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  useOutsideAlerter(inputRef, () => setShowDropdown(false));
  const handleSelect = (option) => {
    if (option !== disabledOption) {
      onSelect(option);
      setShowDropdown(false);
      onToggleDropdown(id);
    }
  };

  useEffect(() => {
    if (activeDropdownId !== id) {
      setShowDropdown(false);
    }
  }, [activeDropdownId, id]);

  return (
    <div ref={inputRef} style={{ position: 'relative' }}>
      <div
        className="GenerateAiTourForm__inputContainer"
        onClick={() => {
          if (!disabledOption) {
            setShowDropdown((prev) => !prev);
            onToggleDropdown(id);
          }
        }}
      >
        <input
          readOnly
          className={`GenerateAiTourForm__input ${
            value ? 'GenerateAiTourForm__input_active' : ''
          }`}
          value={value || ''}
          placeholder=" "
        />
        <label className="GenerateAiTourForm__label">{label}</label>
        <div className="GenerateAiTourForm__chevron">
          {!showDropdown ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </div>
        {showDropdown && (
          <div className="GenerateAiTourForm__popup">
            {options.length === 0 ? (
              <div className="GenerateAiTourForm__popup_spinner">
                <Spinner />
              </div>
            ) : (
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {options.map((option, index) => (
                  <li
                    key={index}
                    className={`GenerateAiTourForm__listItem ${
                      option === disabledOption ? 'disabled' : ''
                    }`}
                    style={{
                      cursor:
                        option === disabledOption ? 'not-allowed' : 'pointer',
                      color: option === disabledOption ? 'gray' : 'black',
                    }}
                    onMouseDown={() => handleSelect(option)}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
