// TODO: replace with saga fetching /api/currencies/
export const CURRENCIES_SYMBOLS = {
  EUR: '€',
  RUB: '₽',
  USD: '$',
  BYN: 'BYN',
  CHF: 'CHF',
  KZT: '₸',
  GBP: '£',
};

export const getCurrencySymbol = (code) => {
  return CURRENCIES_SYMBOLS.hasOwnProperty(code)
    ? CURRENCIES_SYMBOLS[code]
    : ',-';
};
