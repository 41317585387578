import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import { showNetworkError as showNetworkErrorAction } from '../../actions/network';
import * as UserActions from '../../actions/user';
import * as QuestActions from '../../actions/quests';
import * as ReferalActions from '../../actions/referal';
import * as StatsActions from '../../actions/stats';
import * as PartnerActions from '../../actions/partner';
import ProfileAside from '../ProfileAside';
// eslint-disable-next-line import/no-cycle
import ProfileTours from '../ProfileTours';
import './Profile.css';
import ProfileWelcome from '../ProfileWelcome';
// eslint-disable-next-line import/no-cycle
import ProfileReports from '../ProfileReports';
import ProfileReviews from '../ProfileReviews';
import ProfileSettings from '../ProfileSettings';
import debounce from '../../functions/debounce';
import HelmetTitle from '../HelmetTitle';
import { isUserAuthor } from '../../functions/user';

export const Sections = {
  password: ['newPassword', 'oldPassword'],
  personal: ['firstName', 'lastName'],
};

export const messages = defineMessages({
  eventTitleEnd: {
    id: 'Quests.eventTitleEnd',
    defaultMessage: 'Conclusion',
  },
  eventTitleStart: {
    id: 'Quests.eventTitleStart',
    defaultMessage: 'Introduction',
  },
  title: {
    id: 'Quests.titleTag',
    defaultMessage: 'Conclusion',
  },
  profileTitle: {
    id: 'Profile.profile',
    defaultMessage: 'Profile',
  },
});

const Profile = (props) => {
  const {
    user,
    partner,
    referal,
    isResizeBlock,
    isSidebarOpen,
    closeSidebar,
    intl,
    intl: { formatMessage },
    quests,
    userActions,
    questActions: { deleteQuest, addQuest },
    statsActions,
    statsActions: { requestQuestsStats },
    referalActions,
    partnerActions,
    stats,
    showNetworkError,
  } = props;

  if (
    user.isLogged &&
    !isUserAuthor(user) &&
    !user.isSales &&
    !user.isHijacked &&
    !user.isStaff
  ) {
    window.location.pathname = `${user.locale}/authors/onboarding/2`;
  }

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickname, setNickname] = useState('');
  const [bio, setBio] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [vat, setVat] = useState('');
  const [phone, setPhoneNumber] = useState(user.phone);

  const [address, setAddress] = useState('');
  const [index, setIndex] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [inn, setInn] = useState('');
  const [bankName, setBankName] = useState('');
  const [bic, setBic] = useState('');
  const [swift, setSwift] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [formInput, setFormInput] = useState('');

  const [affiliateSites, setAffiliateSites] = useState('');
  const [affiliateDescription, setAffiliateDescription] = useState('');
  /**
   * Validate and send data to server
   * @param {String} nextValueUnsafe - user value for passed attribute
   * @param {String} actionCreator - action creator for send data
   * @param {String} oldValue - current string from server
   * @param {String} attribute - attribute key name
   */
  const handleSave = (nextValueUnsafe, actionCreator, oldValue, attribute) => {
    const nextValue = nextValueUnsafe.trim();
    if (nextValue === oldValue) return;
    actionCreator({
      [attribute]: nextValue,
    });
  };

  /**
   * Memoized and debounced wrap of handleSave
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSave = useMemo(() => debounce(handleSave, 1000), [user]);

  /**
   * Update local states
   */
  useEffect(
    () => {
      if (user.id !== null) {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setNickname(user.nickname);
        setBio(partner.data.bio);
        if (user.requisites.id) {
          setAddress(user.requisites.address);
          setIndex(user.requisites.index);
          setCompanyName(user.requisites.name);
          setInn(user.requisites.taxNumber);
          setVat(user.requisites.vat);
          setBankName(user.requisites.bankName);
          setBic(user.requisites.bankBic);
          setSwift(user.requisites.bankSwift);
          setAccountNumber(user.requisites.accountNumber);
          setFormInput(user.requisites.formFile);
        }
        if (partner.affiliate.id) {
          setAffiliateSites(partner.affiliate.sites);
          setAffiliateDescription(partner.affiliate.description);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.id, partner.data.nickname, user.requisites.id, partner.affiliate.id]
  );

  function getUserStatuses() {
    const statuses = [];
    if (user.isSuperuser) statuses.push(['superuser', 'Администратор']);
    if (user.isPartner) statuses.push(['partner', 'Партнер']);
    if (user.isSales) statuses.push(['sales', 'Реферальный партнёр']);
    if (!statuses.length) statuses.push(['creator', 'Творец']);
    return statuses;
  }

  function handleAddQuest() {
    addQuest('', [
      formatMessage(messages.eventTitleStart),
      formatMessage(messages.eventTitleEnd),
    ]);
  }

  return (
    <span>
      <div className="App__row">
        <HelmetTitle message={messages.profileTitle} />
        <div
          className={classNames(
            'App__aside-profile',
            isSidebarOpen && 'App__aside-profile--on',
            isResizeBlock && 'App__aside-profile--resize'
          )}
        >
          <ProfileAside
            user={user}
            intl={intl}
            partner={partner}
            statuses={getUserStatuses()}
            closeSidebar={closeSidebar}
            logoutUser={userActions.logoutUser}
          />
        </div>
        <section className="App__main">
          <Switch>
            <Route
              path="/welcome"
              render={() => (
                <ProfileWelcome
                  user={user}
                  questsLength={stats.questsStats.results.length}
                  // eslint-disable-next-line react/jsx-no-bind
                  handleAddQuest={handleAddQuest}
                />
              )}
            />
            <Route
              path="/reports"
              render={() => (
                <ProfileReports
                  stats={stats}
                  partner={partner}
                  user={user}
                  intl={intl}
                  referal={referal}
                  referalActions={referalActions}
                  statsActions={statsActions}
                  partnerActions={partnerActions}
                  userActions={userActions}
                  showNetworkError={showNetworkError}
                />
              )}
            />
            <Route
              path="/reviews"
              render={() => (
                <ProfileReviews
                  partner={partner}
                  user={user}
                  quests={quests}
                  isPartner={user.isPartner}
                />
              )}
            />
            <Route
              path="/settings"
              render={() => (
                <ProfileSettings
                  debouncedHandleSave={debouncedHandleSave}
                  userActions={userActions}
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  nickname={nickname}
                  setNickname={setNickname}
                  bio={bio}
                  setBio={setBio}
                  oldPassword={oldPassword}
                  setOldPassword={setOldPassword}
                  newPassword={newPassword}
                  setNewPassword={setNewPassword}
                  address={address}
                  setAddress={setAddress}
                  vat={vat}
                  setVat={setVat}
                  phone={phone}
                  setPhoneNumber={setPhoneNumber}
                  index={index}
                  setIndex={setIndex}
                  companyName={companyName}
                  setCompanyName={setCompanyName}
                  inn={inn}
                  setInn={setInn}
                  bankName={bankName}
                  setBankName={setBankName}
                  bic={bic}
                  setBic={setBic}
                  swift={swift}
                  setSwift={setSwift}
                  accountNumber={accountNumber}
                  setAccountNumber={setAccountNumber}
                  formInput={formInput}
                  setFormInput={setFormInput}
                  affiliateSites={affiliateSites}
                  setAffiliateSites={setAffiliateSites}
                  affiliateDescription={affiliateDescription}
                  setAffiliateDescription={setAffiliateDescription}
                  user={user}
                  partner={partner}
                  intl={intl}
                />
              )}
            />
            <Route
              path="/"
              render={() => (
                <ProfileTours
                  user={user}
                  deleteQuest={deleteQuest}
                  requestQuestsStats={requestQuestsStats}
                  quests={quests}
                  stats={stats}
                  intl={intl}
                  // eslint-disable-next-line react/jsx-no-bind
                  handleAddQuest={handleAddQuest}
                />
              )}
            />

            {stats.questsStats.count === 0 ? (
              <Redirect to="/welcome" />
            ) : (
              <Redirect to="/" />
            )}
          </Switch>
        </section>
      </div>
    </span>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    partner: state.partner,
    quests: state.quests,
    referal: state.referal,
    stats: state.stats,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    questActions: bindActionCreators(QuestActions, dispatch),
    referalActions: bindActionCreators(ReferalActions, dispatch),
    statsActions: bindActionCreators(StatsActions, dispatch),
    partnerActions: bindActionCreators(PartnerActions, dispatch),
    showNetworkError: bindActionCreators(showNetworkErrorAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Profile));
