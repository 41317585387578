import React, { useState } from 'react';
import {
  Button as ButtonMui,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, defineMessages } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Alert } from '@material-ui/lab';
import AvatarEditor from 'react-avatar-edit';
import dropzoneImg from '../../img/avatarExamples/dropzoneImg.png';
import goodImg1 from '../../img/avatarExamples/goodImg1.png';
import badImg1 from '../../img/avatarExamples/badImg1.png';
import badImg2 from '../../img/avatarExamples/badImg2.png';
import badImg3 from '../../img/avatarExamples/badImg3.png';
import { createImage, getImageRotatedRight } from '../AvatarEditor';
import Examples from '../Examples';
import {
  ABOUT_USER_CHARACTER_LIMIT,
  ABOUT_PARTNER_CHARACTER_LIMIT,
  PARTNER_RESOURCE_CHARACTER_LIMIT,
} from '../../constants/limits';
import DropzonePictures from '../Dropzone/withPictures';
import Button from '../Button';
import ProfileContent from '../ProfileContent';
import Popup from '../Popup';
import HelmetTitle from '../HelmetTitle';
import { DOMAIN_URL } from '../../constants/branding';
import { isUserAuthor } from '../../functions/user';
import ProfileSettingsRequisites from './ProfileSettingsRequisites';

const useStyles = makeStyles(() => ({
  select: {
    maxWidth: 300,
    width: '90%',
    display: 'flex',
  },
  selectHelperText: {
    marginBottom: 30,
  },
  textFieldBlock: {
    maxWidth: 400,
    width: '90%',
    display: 'flex',
    marginBottom: 30,
  },
  textFieldInline: {
    display: 'inline-flex',
    maxWidth: 330,
    width: '90%',
    marginRight: 15,
    marginBottom: 30,
  },
  textFieldFullWidth: {
    maxWidth: 680,
    display: 'flex',
    marginBottom: 30,
  },
  alert: {
    width: 'fit-content',
    marginTop: 4,
  },
  textFieldArea: {
    maxWidth: 680,
    display: 'flex',
  },
  h6: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 20,
    marginTop: 30,
  },
  dropzone: {
    marginBottom: 16,
  },
  subtitle: {
    marginBottom: 15,
    whiteSpace: 'pre-wrap',
  },
  h5: {
    marginBottom: -10,
  },
  settingsContainer: {
    maxWidth: 780,
  },
  bioExamplesContainer: {
    marginTop: 16,
    marginBottom: 30,
  },
  remindPassword: {
    cursor: 'pointer',
    float: 'right',
  },
  goToProfile: {
    cursor: 'pointer',
  },
  errorAlert: {
    maxWidth: 'fit-content',
    marginBottom: 15,
  },
  popup: {
    alignItems: 'center',
  },
  avatarButtons: {
    margin: 15,
  },
  popupTitle: {
    alignSelf: 'baseline',
    marginLeft: 28,
    marginTop: 12,
  },
  tabs: {
    marginTop: 22,
  },
}));

const messages = defineMessages({
  aboutPlaceholder: {
    id: 'ProfileSettings.aboutPlaceholder',
    defaultMessage:
      'Share what you love, such as discovering new places, walking at sunset and listening to cool music',
  },
  aboutPartnerPlaceholder: {
    id: 'ProfileSettings.aboutPartnerPlaceholder',
    defaultMessage:
      'Tell us what you do, who is your main audience, its size and region. Attach additional links if necessary.',
  },
  partnerPlaceholder: {
    id: 'ProfileSettings.partnerPlaceholder',
    defaultMessage: 'https://travelblog.com',
  },
  goodExample: {
    id: 'ProfileSettings.goodExample',
    defaultMessage: 'The face is visible, an even contrasting background',
  },
  badExample: {
    id: 'ProfileSettings.badExample',
    defaultMessage: 'Black and white photo, low quality',
  },
  badExample2: {
    id: 'ProfileSettings.badExample2',
    defaultMessage: 'Too abstract',
  },
  badExample3: {
    id: 'ProfileSettings.badExample3',
    defaultMessage: 'The face is not visible',
  },
  settingsTitle: {
    id: 'ProfileSettings.settingsTitle',
    defaultMessage: 'Settings',
  },
});

const Errors = {
  'Invalid password': (
    <FormattedMessage
      id="ProfileSettings.InvalidPassword"
      defaultMessage="Invalid password"
    />
  ),
  'Password should be at least 6 chars': (
    <FormattedMessage
      id="ProfileSettings.passwordSixChars"
      defaultMessage="Password must be more than six characters"
    />
  ),
  'Server Error': (
    <FormattedMessage
      id="ProfileSettings.serverError"
      defaultMessage="Server error"
    />
  ),
};

function Error({ message }) {
  const classes = useStyles();

  return (
    <Alert className={classes.errorAlert} severity="error">
      {Errors[message]}
    </Alert>
  );
}

const ProfileSettings = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  bio,
  setBio,
  oldPassword,
  setOldPassword,
  newPassword,
  setNewPassword,
  address,
  setAddress,
  vat,
  setVat,
  phone,
  setPhoneNumber,
  index,
  setIndex,
  companyName,
  setCompanyName,
  inn,
  setInn,
  bankName,
  setBankName,
  bic,
  setBic,
  swift,
  setSwift,
  accountNumber,
  setAccountNumber,
  formInput,
  setFormInput,
  affiliateSites,
  setAffiliateSites,
  affiliateDescription,
  setAffiliateDescription,
  user,
  user: { isPartner, locale, errors },
  partner: {
    affiliate: { sites, description },
  },
  intl: { formatMessage },
  userActions: {
    changeLanguage,
    updateProfile,
    updatePartnerData,
    changePassword,
    uploadAvatar,
    updateRequisites,
    updateAffiliatePartnerData,
  },
  debouncedHandleSave,
}) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const [dropped, setDropped] = useState(null);
  const [isAvatarEditorEnabled, setIsAvatarEditorEnabled] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const isAuthor = isUserAuthor(user);

  const handleChangePassword = () => {
    changePassword({ newPassword, oldPassword });
    setOldPassword('');
    setNewPassword('');
  };

  const saveAvatar = () => {
    setDropped(avatarPreview);
    uploadAvatar({ image: avatarPreview });
    setIsAvatarEditorEnabled(false);
  };

  const onCropAvatar = (newAvatarPreview) => {
    setAvatarPreview(newAvatarPreview);
  };

  const onCloseAvatarEditor = () => {
    setAvatarPreview(null);
    setDropped(null);
    setIsAvatarEditorEnabled(false);
  };

  const helperText = (
    <FormattedMessage
      id="ProfileSettings.helperText"
      defaultMessage="Enter to change"
    />
  );

  const renderSettings = () => {
    return (
      <div className={classes.settingsContainer}>
        <Typography gutterBottom className={classes.h6} variant="h6">
          <FormattedMessage
            id="ProfileSettings.yourPortrait"
            defaultMessage="Your portrait"
          />
        </Typography>
        <DropzonePictures
          withImage
          image={user.avatar ? user.avatar : dropzoneImg}
          className={classes.dropzone}
          accept="image/*"
          onDropAccepted={async (files) => {
            setDropped(await getImageRotatedRight(files[0]));
            setIsAvatarEditorEnabled(true);
          }}
        />
        <Popup className={classes.popup} isOpen={isAvatarEditorEnabled}>
          <Typography className={classes.popupTitle} variant="h4">
            <FormattedMessage
              id="ProfileSettings.avatar"
              defaultMessage="Avatar"
            />
          </Typography>
          {dropped && (
            <AvatarEditor
              img={createImage(dropped)}
              width={200}
              height={200}
              imageWidth={200}
              borderStyle={{ textAlign: 'center' }}
              onCrop={onCropAvatar}
              onClose={onCloseAvatarEditor}
            />
          )}
          <div>
            <Button
              className={classes.avatarButtons}
              theme="action"
              onClick={() => saveAvatar()}
            >
              <FormattedMessage
                id="ProfileSettings.save"
                defaultMessage="Save"
              />
            </Button>
            <Button
              className={classes.avatarButtons}
              theme="action"
              onClick={() => onCloseAvatarEditor()}
            >
              <FormattedMessage
                id="ProfileSettings.cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </div>
        </Popup>
        <Typography className={classes.subtitle} variant="body2">
          <FormattedMessage
            id="ProfileSettings.yourPortraitSubtitle"
            defaultMessage={`Travelers are more likely to choose tours if the author has a real portrait loaded.
            It will appear on your tour pages and on your personal profile. Here are the photos we recommend and don't recommend using:`}
          />
        </Typography>
        <Examples
          withPhotos
          good={[
            {
              src: goodImg1,
              text: formatMessage(messages.goodExample),
            },
          ]}
          bad={[
            {
              src: badImg1,
              text: formatMessage(messages.badExample),
            },
            {
              src: badImg2,
              text: formatMessage(messages.badExample2),
            },
            {
              src: badImg3,
              text: formatMessage(messages.badExample3),
            },
          ]}
        />

        <Typography className={classes.h6} variant="h6">
          <FormattedMessage
            id="ProfileSettings.userData"
            defaultMessage="Your personal data"
          />
        </Typography>

        <Select
          value={locale}
          className={classes.select}
          onChange={({ target: { value } }) => changeLanguage(value)}
        >
          <MenuItem key={1} value="ru">
            <FormattedMessage
              id="ProfileSettings.rusLang"
              defaultMessage="Russian"
            />
          </MenuItem>
          <MenuItem key={2} value="en">
            <FormattedMessage
              id="ProfileSettings.engLang"
              defaultMessage="English"
            />
          </MenuItem>
        </Select>
        <FormHelperText className={classes.selectHelperText}>
          <FormattedMessage
            id="ProfileSettings.languageHelperText"
            defaultMessage="Interface language"
          />
        </FormHelperText>

        <TextField
          className={classes.textFieldInline}
          value={firstName}
          variant="standard"
          type="MuiText"
          label={
            <FormattedMessage id="ProfileSettings.name" defaultMessage="Name" />
          }
          helperText={
            <FormattedMessage
              id="ProfileSettings.recommendation"
              defaultMessage="Use your real name and surname"
            />
          }
          onChange={({ target: { value } }) => {
            setFirstName(value);
            debouncedHandleSave(
              value,
              updateProfile,
              user.firstName,
              'firstName'
            );
          }}
        />

        <TextField
          className={classes.textFieldInline}
          value={lastName}
          variant="standard"
          type="MuiText"
          label={
            <FormattedMessage
              id="ProfileSettings.surname"
              defaultMessage="Surname"
            />
          }
          onChange={({ target: { value } }) => {
            setLastName(value);
            debouncedHandleSave(
              value,
              updateProfile,
              user.lastName,
              'lastName'
            );
          }}
        />

        <TextField
          className={classes.textFieldInline}
          value={phone}
          variant="standard"
          type="MuiText"
          label={
            <FormattedMessage
              id="ProfileSettings.phoneNumber"
              defaultMessage="Phone number"
            />
          }
          onChange={({ target: { value } }) => {
            setPhoneNumber(value);
            debouncedHandleSave(value, updateProfile, phone, 'phone');
          }}
        />

        {isAuthor && (
          <React.Fragment>
            <TextField
              multiline
              className={classes.textFieldArea}
              label={
                <FormattedMessage
                  id="ProfileSettings.aboutLabel"
                  defaultMessage="Tell a little about yourself"
                />
              }
              rows={1}
              rowsMax={40}
              variant="standard"
              value={bio}
              inputProps={{ maxLength: ABOUT_USER_CHARACTER_LIMIT }}
              placeholder={formatMessage(messages.aboutPlaceholder)}
              helperText={
                <FormattedMessage
                  id="ProfileSettings.aboutHelperText"
                  defaultMessage="Maximum 400 characters. This text will appear on your profile on {link} website. Please do not insert any links here."
                  values={{
                    link: (
                      <a
                        className={classes.aboutLink}
                        target="_blank"
                        href={DOMAIN_URL}
                        rel="noopener noreferrer"
                      >
                        {window.location.host}
                      </a>
                    ),
                  }}
                />
              }
              onChange={({ target: { value } }) => {
                setBio(value);
                debouncedHandleSave(value, updatePartnerData, user.bio, 'bio');
              }}
            />
            <div className={classes.bioExamplesContainer}>
              <Examples
                orientation="vertical"
                good={[
                  <FormattedMessage
                    key="bioGoodExample1"
                    id="ProfileSettings.bioGoodExample1"
                    defaultMessage="Hello or, as the Finns say, terve! I invite you to take a walk around Helsinki together and hope that you will love the city as much as I do. An original tour is the result of natural curiosity and an ever-growing affection for the city. I sincerely believe that a traveler is a state of mind, and I am glad to share this mood with like-minded people!"
                  />,
                ]}
                bad={[
                  <FormattedMessage
                    key="bioBadExample1"
                    id="ProfileSettings.bioBadExample1"
                    defaultMessage="I live in Moscow. Cool city."
                  />,
                  <FormattedMessage
                    key="bioBadExample2"
                    id="ProfileSettings.bioBadExample2"
                    defaultMessage="Hi everyone. I have been living in St. Petersburg for 1 year. I love Pilates and cats. Come to my instagram."
                  />,
                ]}
              />
            </div>
          </React.Fragment>
        )}

        {isPartner && (
          <React.Fragment>
            <TextField
              multiline
              className={classes.textFieldFullWidth}
              label={
                <FormattedMessage
                  id="ProfileSettings.PartnerLabel"
                  defaultMessage="URL of your website, social page, etc"
                />
              }
              type="text"
              variant="standard"
              value={affiliateSites}
              inputProps={{ maxLength: PARTNER_RESOURCE_CHARACTER_LIMIT }}
              placeholder={formatMessage(messages.partnerPlaceholder)}
              onChange={({ target: { value } }) => {
                setAffiliateSites(value);
                debouncedHandleSave(
                  value,
                  updateAffiliatePartnerData,
                  sites,
                  'sites'
                );
              }}
            />

            <TextField
              multiline
              className={classes.textFieldArea}
              label={
                <FormattedMessage
                  id="ProfileSettings.aboutPartnerLabel"
                  defaultMessage="Describe your resource"
                />
              }
              rows={1}
              rowsMax={40}
              variant="standard"
              value={affiliateDescription}
              inputProps={{
                maxLength: ABOUT_PARTNER_CHARACTER_LIMIT,
                autoComplete: 'off',
              }}
              placeholder={formatMessage(messages.aboutPartnerPlaceholder)}
              helperText={
                <FormattedMessage
                  id="ProfileSettings.aboutPartnerHelperText"
                  defaultMessage="Maximum 300 characters."
                />
              }
              onChange={({ target: { value } }) => {
                setAffiliateDescription(value);
                debouncedHandleSave(
                  value,
                  updateAffiliatePartnerData,
                  description,
                  'description'
                );
              }}
            />
          </React.Fragment>
        )}

        <Typography className={classes.h6} variant="h6">
          <FormattedMessage
            id="ProfileSettings.password"
            defaultMessage="Password"
          />
        </Typography>
        {errors
          .filter((e) => e.section === 'password')
          .map((e) => (
            <Error key={e.code} {...e} />
          ))}
        <TextField
          className={classes.textFieldBlock}
          value={oldPassword}
          variant="standard"
          type="password"
          label={
            <FormattedMessage
              id="ProfileSettings.currentPassword"
              defaultMessage="Current password"
            />
          }
          helperText={helperText}
          inputProps={{
            autoComplete: 'off',
          }}
          onChange={({ target: { value } }) => setOldPassword(value)}
        />
        <TextField
          className={classes.textFieldBlock}
          value={newPassword}
          variant="standard"
          type="password"
          label={
            <FormattedMessage
              id="ProfileSettings.newPassword"
              defaultMessage="New password"
            />
          }
          helperText={helperText}
          inputProps={{
            autoComplete: 'off',
          }}
          onChange={({ target: { value } }) => setNewPassword(value)}
        />
        <ButtonMui
          disabled={!(oldPassword.length > 1 && newPassword.length > 1)}
          variant="contained"
          color="primary"
          onClick={() => handleChangePassword()}
        >
          <FormattedMessage
            id="ProfileSettings.updatePassword"
            defaultMessage="Update password"
          />
        </ButtonMui>
      </div>
    );
  };

  return (
    <ProfileContent>
      <HelmetTitle message={messages.settingsTitle} />
      <Typography className={classes.h5} variant="h5">
        <FormattedMessage
          id="ProfileSettings.settings"
          defaultMessage="Settings"
        />
      </Typography>
      <Tabs
        className={classes.tabs}
        value={pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          value="/settings/profile"
          component={Link}
          to="/settings/profile"
          label={
            <FormattedMessage
              id="ProfileSettings.profileTab"
              defaultMessage="Profile"
            />
          }
        />
        <Tab
          value="/settings/requisites"
          component={Link}
          to="/settings/requisites"
          label={
            <FormattedMessage
              id="ProfileSettings.paymentDetails"
              defaultMessage="Payment details"
            />
          }
        />
      </Tabs>
      {pathname === '/settings/profile' ? renderSettings() : null}
      {pathname === '/settings/requisites' ? (
        <ProfileSettingsRequisites
          address={address}
          setAddress={setAddress}
          vat={vat}
          setVat={setVat}
          index={index}
          setIndex={setIndex}
          companyName={companyName}
          setCompanyName={setCompanyName}
          inn={inn}
          setInn={setInn}
          bankName={bankName}
          setBankName={setBankName}
          bic={bic}
          setBic={setBic}
          swift={swift}
          setSwift={setSwift}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          formInput={formInput}
          setFormInput={setFormInput}
          debouncedHandleSave={debouncedHandleSave}
          updateRequisites={updateRequisites}
          user={user}
        />
      ) : null}
    </ProfileContent>
  );
};

export default ProfileSettings;
