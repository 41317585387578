/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable object-shorthand */
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { checkTaskStatus } from '../../functions/checkTaskStatus';
import { fetchTranslateTour } from '../../functions/fetchTranslateTour';
import Button from '../Button';
import SpinnerPopup from '../GenerateAiTourForm/SpinnerPopup';
import { translations } from '../GenerateAiTourForm/translations';
import { ChevronDownIcon, ChevronUpIcon, ShareIcon, StarIcon } from '../Icon';
import './TranslationForm.css';
import { DOMAIN_URL, TEXT_TO_SPEACH_GOOGLE } from '../../constants/branding';
import { fetchUpdatedQuest } from '../../sagas/quests';
import { RadioButton } from '../GenerateAiTourForm/RadioButton';
import { FetchVoices } from '../GenerateAiTourForm/FetchVoices';
import { performAction } from '../GenerateAiTourForm/PerformAction';
import { DropdownInput } from '../GenerateAiTourForm/DropdownInputForm';
import { languages } from './languages';

const TranslationForm = ({
  currentQuest,
  user,
  formatMessage,
  showBothButtons,
  fetchUpdatedQuest,
}) => {
  const lang = user.locale;

  const [taskId, setTaskId] = useState(
    localStorage.getItem(`taskId-${currentQuest.id}`)
  );
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [taskStatus, setTaskStatus] = useState(null);
  const [activeLanguageCodes, setActiveLanguageCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAllLanguages, setShowAllLanguages] = useState(false);
  const [showAllTranslatedLanguages, setShowAllTranslatedLanguages] = useState(
    false
  );
  const [voices, setVoices] = useState([]);
  const [voiceSearchQuery, setVoiceSearchQuery] = useState('');
  const [stepAudioOption, setStepAudioOption] = useState('allSteps');
  const [stepAudio, setStepAudio] = useState('');
  const [audioSource, setAudioSource] = useState('');
  const [individStepsAudio, setIndividStepsAudioAudio] = useState('');
  const [selectedStepAudio, setSelectedStepAudio] = useState('');
  const [textErrors, setTextErrors] = useState({});
  const [fieldErrors, setFieldErrors] = useState(false);

  const totalSteps = currentQuest.events.length - 1;
  const getLanguageDisplayName = (language) => {
    return lang === 'ru' ? language.nameRu : language.name;
  };

  const validateFieldWithLimit = (fieldName, value, maxLimit) => {
    let errorMessage = null;
    const numericValue = parseInt(value, 10);

    if (numericValue > maxLimit) {
      errorMessage = (
        <span className="GenerateAiTourForm__errorText">
          {formatMessage(translations.valueCannotBeMoreThan)} {maxLimit}.
        </span>
      );
    }

    setTextErrors((prev) => ({ ...prev, [fieldName]: errorMessage }));
    setFieldErrors(errorMessage !== null);
  };

  const handleIndividStepsAudioChange = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9]/g, '');
    setIndividStepsAudioAudio(onlyNums);
    validateFieldWithLimit('individStepsAudio', onlyNums, totalSteps);
  };

  useEffect(() => {
    if (currentQuest.generatedByAi) {
      const loadVoices = async () => {
        const fetchedVoices = await FetchVoices(
          lang,
          currentQuest.id,
          audioSource
        );
        setVoices(fetchedVoices || []);
      };

      loadVoices();
    }
  }, [currentQuest.id, currentQuest.generatedByAi, audioSource]);

  useEffect(() => {
    if (stepAudioOption === 'individualSteps' && currentQuest.voice !== ' ') {
      setStepAudio(currentQuest.voice.toString());
      setSelectedStepAudio(currentQuest.voice);
    }
  }, [currentQuest.voice, stepAudioOption]);

  const translatedLanguagesWithIds = useMemo(() => {
    return currentQuest.copies
      .filter(
        (copy) =>
          currentQuest.language.code !== 'en' || copy.language.code !== 'en'
      )
      .map((copy) => ({
        id: copy.id,
        code: copy.language.code,
        name: copy.language.name,
      }));
  }, [currentQuest.copies, lang]);

  const uniqueTranslatedLanguageCodes = new Set(
    translatedLanguagesWithIds.map((lang) => lang.code)
  );
  const languagesForTranslation = useMemo(
    () =>
      languages.filter((language) => {
        const currentQuestLanguageCode = currentQuest?.language?.code;

        return (
          !uniqueTranslatedLanguageCodes.has(language.code) &&
          (currentQuestLanguageCode !== 'en' || language.code !== 'en')
        );
      }),
    [languages, uniqueTranslatedLanguageCodes, currentQuest?.language?.code]
  );

  const displayedTranslatedLanguages = useMemo(
    () =>
      translatedLanguagesWithIds
        .filter(({ code }) => {
          return code !== 'en' || currentQuest.language.code !== 'en';
        })
        .map((copy) => ({
          ...copy,
          synchronized: currentQuest.copies.find((c) => c.id === copy.id)
            ?.synchronized,
        }))
        .slice(0, showAllTranslatedLanguages ? undefined : 3),
    [
      translatedLanguagesWithIds,
      showAllTranslatedLanguages,
      currentQuest.copies,
      currentQuest.language.code,
    ]
  );

  useEffect(() => {
    const fetchStatus = async () => {
      if (!taskId) return;

      setIsLoading(true);
      try {
        const { status, error } = await checkTaskStatus(
          taskId,
          currentQuest.id
        );
        if (!error) {
          setTaskStatus(status);
          setIsPopupVisible(status === 'PENDING');
        }
      } catch (error) {
        console.error('Error fetching task status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStatus();

    const intervalId = taskId ? setInterval(fetchStatus, 30000) : null;
    return () => clearInterval(intervalId);
  }, [taskId, currentQuest.id]);

  useEffect(() => {
    fetchUpdatedQuest(currentQuest.id);
  }, [fetchUpdatedQuest, currentQuest.id]);

  useEffect(() => {
    if (['SUCCESS', 'ERROR'].includes(taskStatus)) {
      if (taskStatus === 'SUCCESS') {
        fetchUpdatedQuest(currentQuest.id);
      }
      localStorage.removeItem(`taskId-${currentQuest.id}`);
      setTaskId(null);
      setIsPopupVisible(false);
    } else if (taskStatus === 'PENDING') {
      setIsPopupVisible(true);
    }
  }, [taskStatus, currentQuest.id]);

  const handleLanguageClick = (code) =>
    setActiveLanguageCodes((codes) =>
      codes.includes(code) ? codes.filter((c) => c !== code) : [...codes, code]
    );

  const handleGenerateClick = async (questId, languageCodes) => {
    setIsLoading(true);
    try {
      const response = await fetchTranslateTour(questId, languageCodes);
      if (response && response[0].taskId) {
        setTaskId(response[0].taskId);
        localStorage.setItem(`taskId-${questId}`, response[0].taskId);
        setTaskStatus('PENDING');
      } else {
        console.error("Task ID wasn't returned from the API.");
      }
    } catch (error) {
      console.error('Error while generating:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActionClick = async (actionType, options = {}) => {
    setIsLoading(true);
    try {
      const response = await performAction(currentQuest, actionType, options);
      if (response && response.taskId) {
        setTaskId(response.taskId);
        localStorage.setItem(`taskId-${questId}`, response.taskId);
        setTaskStatus('PENDING');
      }
    } catch (error) {
      console.error('Error while generating:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const VISIBLE_LANGUAGES_COUNT = 9;

  const visibleLanguages = showAllLanguages
    ? languagesForTranslation
    : languagesForTranslation.slice(0, VISIBLE_LANGUAGES_COUNT);

  const toggleShowAllLanguages = () => setShowAllLanguages(!showAllLanguages);

  const getInputClasses = (isActive) => {
    let classes = 'TranslationForm__input ';

    if (isActive) {
      classes += 'TranslationForm__input_active';
    }

    const hasError = fieldErrors;

    if (hasError) {
      classes += ' GenerateAiTourForm__input--error';
    }

    return classes;
  };
  const handleStepAudioOptionChange = (newOption) => {
    setStepAudioOption(newOption);
    if (newOption === 'allSteps') {
      setTextErrors({});
      setFieldErrors(false);
      setIndividStepsAudioAudio('');
    }
  };
  const stepAudioSourseDropdownOptions = ['Google', 'ElevenLabs'];
  const [activeDropdownId, setActiveDropdownId] = useState(null);

  const handleToggleDropdown = (id) => {
    setActiveDropdownId((prevId) => (prevId === id ? null : id));
  };
  return (
    <>
      {!showBothButtons &&
      currentQuest.aitourId === null &&
      currentQuest.generatedByAi === true ? (
        <div className="TranslationForm">
          <SpinnerPopup
            formatMessage={formatMessage}
            isOpen={isPopupVisible || isLoading}
            handleCloseClick={() => setIsPopupVisible(false)}
          />
          <div className="TranslationForm__section">
            <div className="TranslationForm__title">
              {' '}
              {formatMessage(translations.tourLanguage)}
            </div>
            <div className="TranslationForm__languages-translated-block">
              <div>
                <div className="TranslationForm__languages-translated-block_title">
                  {currentQuest.language.name}
                </div>
                <div
                  className={`TranslationForm__languages-translated-block_subtitle ${
                    !currentQuest.originalTour.synchronized
                      ? 'TranslationForm__languages-translated-block_subtitle-notSynchronized'
                      : ''
                  }`}
                >
                  {currentQuest.originalTour.synchronized
                    ? formatMessage(translations.Synchronized)
                    : formatMessage(translations.noSynchronized)}
                </div>
              </div>
            </div>

            <Button
              className="TranslationForm__generate-button"
              size="medium"
              disabled={currentQuest.originalTour.synchronized}
              onClick={() =>
                handleGenerateClick(currentQuest.originalTour.id, [
                  currentQuest.language.code,
                ])
              }
            >
              {formatMessage(translations.update)}
            </Button>

            <div className="TranslationForm__section">
              <div className="TranslationForm__title">
                {formatMessage(translations.stepAudio)}
              </div>
              <DropdownInput
                id="audioSource"
                label={formatMessage(translations.AudioSource)}
                value={audioSource}
                options={stepAudioSourseDropdownOptions}
                activeDropdownId={activeDropdownId}
                onSelect={setAudioSource}
                onToggleDropdown={handleToggleDropdown}
              />
              <div
                className="TranslationForm__section_button-block"
                onChange={(event) => setStepAudioOption(event.target.value)}
              >
                <RadioButton
                  label={formatMessage(translations.allSteps)}
                  value="allSteps"
                  checked={stepAudioOption === 'allSteps'}
                  onChange={() => handleStepAudioOptionChange('allSteps')}
                />
                <RadioButton
                  individualSteps
                  label={formatMessage(translations.individualSteps)}
                  value="individualSteps"
                  checked={stepAudioOption === 'individualSteps'}
                  onChange={() =>
                    handleStepAudioOptionChange('individualSteps')
                  }
                />
              </div>

              {stepAudioOption === 'individualSteps' && (
                <div style={{ position: 'relative' }}>
                  <div className="TranslationForm__inputContainer">
                    <input
                      placeholder=" "
                      className={`${getInputClasses(individStepsAudio)} ${
                        React.isValidElement(textErrors['individStepsAudio']) &&
                        textErrors['individStepsAudio'].props.children
                          ? 'GenerateAiTourForm__inputContainer_error'
                          : ''
                      }`}
                      value={individStepsAudio}
                      onChange={handleIndividStepsAudioChange}
                    />
                    <label
                      htmlFor="individStepsAudioInput"
                      className="TranslationForm__label"
                    >
                      {formatMessage(translations.stepNumberPlaceholder)}{' '}
                    </label>
                    {textErrors.individStepsAudio}
                  </div>
                </div>
              )}

              <>
                <DropdownInput
                  id="stepAudio"
                  onToggleDropdown={handleToggleDropdown}
                  activeDropdownId={activeDropdownId}
                  label={formatMessage(translations.voiceActing)}
                  value={voiceSearchQuery || stepAudio}
                  options={voices.filter((voice) =>
                    voice.toLowerCase().includes(voiceSearchQuery.toLowerCase())
                  )}
                  onSelect={(selectedVoice) => {
                    setStepAudio(selectedVoice);
                    setVoiceSearchQuery('');
                    handleStepAudioChange(selectedVoice);
                  }}
                  disabledOption={
                    stepAudioOption === 'individualSteps' && selectedStepAudio
                      ? stepAudio
                      : null
                  }
                />
                <a
                  href={`${TEXT_TO_SPEACH_GOOGLE}`}
                  className="TranslationForm__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatMessage(translations.previewOfTheVoice)}{' '}
                </a>
              </>
              <SpinnerPopup
                formatMessage={formatMessage}
                isOpen={isPopupVisible || isLoading}
                handleCloseClick={() => setIsPopupVisible(false)}
              />
              <Button
                className="TranslationForm__generate-button"
                size="medium"
                onClick={() => {
                  const actionParams = {
                    voiceId: stepAudio,
                    audioSource,
                    ...(individStepsAudio && {
                      stepNumberVoice: individStepsAudio,
                    }),
                  };
                  handleActionClick('generateVoice', actionParams);
                }}
                disabled={
                  (stepAudioOption === 'allSteps' && stepAudio === '') ||
                  (stepAudioOption === 'individualSteps' &&
                    (!stepAudio ||
                      !individStepsAudio ||
                      individStepsAudio < 1 ||
                      fieldErrors))
                }
              >
                <StarIcon />
                {formatMessage(translations.generateButton)}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="TranslationForm">
          <div className="TranslationForm__section">
            <div className="TranslationForm__title">
              {formatMessage(translations.sourceLanguage)}
            </div>
            <div className="TranslationForm__language-item TranslationForm__language-item-disabled">
              {formatMessage(translations.englishLanguage)}
            </div>
          </div>
          <SpinnerPopup
            formatMessage={formatMessage}
            isOpen={isPopupVisible || isLoading}
            handleCloseClick={() => setIsPopupVisible(false)}
          />
          {displayedTranslatedLanguages.length > 0 && (
            <div className="TranslationForm__section">
              <div className="TranslationForm__title">
                {formatMessage(translations.translatedLanguages)}
              </div>
              <div className="TranslationForm__languages-translatedContainer">
                {displayedTranslatedLanguages.map(
                  ({ id, code, name, synchronized }) => (
                    <a
                      href={`${DOMAIN_URL}my/quest/${id}/events/0`}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={code}
                      className="TranslationForm__languages-translated-block"
                    >
                      <div>
                        <div className="TranslationForm__languages-translated-block_title">
                          {getLanguageDisplayName({ name: name, nameRu: name })}{' '}
                        </div>
                        <div
                          className={`TranslationForm__languages-translated-block_subtitle ${
                            !synchronized
                              ? 'TranslationForm__languages-translated-block_subtitle-notSynchronized'
                              : ''
                          }`}
                        >
                          {synchronized
                            ? formatMessage(translations.Synchronized)
                            : formatMessage(translations.noSynchronized)}
                        </div>
                      </div>
                      <ShareIcon />
                    </a>
                  )
                )}
              </div>
              {translatedLanguagesWithIds.length > 3 && (
                <button
                  onClick={() =>
                    setShowAllTranslatedLanguages(!showAllTranslatedLanguages)
                  }
                  className="TranslationForm__show-more"
                >
                  {showAllTranslatedLanguages ? (
                    <>
                      {formatMessage(translations.seeLess)} <ChevronUpIcon />
                    </>
                  ) : (
                    <>
                      {formatMessage(translations.seeMore)}
                      <ChevronDownIcon />
                    </>
                  )}
                </button>
              )}
            </div>
          )}

          <div className="TranslationForm__section">
            <div className="TranslationForm__title">
              {formatMessage(translations.addTranslation)}
            </div>
            <div className="TranslationForm__languages-container">
              {visibleLanguages.map((language) => (
                <div
                  key={language.code}
                  className={`TranslationForm__language-item ${
                    activeLanguageCodes.includes(language.code)
                      ? 'TranslationForm__language-item-active'
                      : ''
                  }`}
                  onClick={() => handleLanguageClick(language.code)}
                >
                  {getLanguageDisplayName(language)}
                </div>
              ))}
            </div>
            {languages.length > VISIBLE_LANGUAGES_COUNT && (
              <button
                onClick={toggleShowAllLanguages}
                className="TranslationForm__show-more"
              >
                {showAllLanguages ? (
                  <>
                    {formatMessage(translations.seeLess)} <ChevronUpIcon />
                  </>
                ) : (
                  <>
                    {formatMessage(translations.seeMore)} <ChevronDownIcon />
                  </>
                )}
              </button>
            )}
          </div>
          <Button
            className="TranslationForm__generate-button"
            size="medium"
            onClick={() =>
              handleGenerateClick(currentQuest.id, [activeLanguageCodes])
            }
            disabled={activeLanguageCodes.length === 0}
          >
            <StarIcon />
            {formatMessage(translations.generateButton)}
          </Button>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchUpdatedQuest: (id) => dispatch(fetchUpdatedQuest(id)),
});

export default connect(null, mapDispatchToProps)(TranslationForm);
