import Api from '../../../functions/Api';

export async function submitForm(
  selectedCountryId,
  selectedCityId,
  selectedCategories,
  duration,
  route,
  stops,
  stories,
  textSource,
  topic,
  isTourChecked,
  startingPoint,
  endingPoint,
  currentQuest
) {
  const requestBody = {
    countryId: selectedCountryId,
    cityId: selectedCityId,
    language: 1,
    subcategories: selectedCategories.map((cat) => cat.id),
    voice: '',
    duration: parseInt(duration, 10),
    route_length: parseInt(route, 10),
    number_of_stops: parseInt(stops, 10),
    number_of_stories: parseInt(stories, 10),
    additional_requirements: topic,
    starting_point: startingPoint,
    ending_point: endingPoint,
    chatbot: textSource,
    has_ticket: isTourChecked,
    generated_tour: currentQuest.id,
  };

  const url = currentQuest.aitourId
    ? `quests/aitours/${currentQuest.aitourId}/`
    : 'quests/aitours/';

  const method = currentQuest.aitourId ? 'patch' : 'post';

  try {
    const response = await Api[method](url, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    console.log('response', response);
    return response;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}
