import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ label, subtitle, isChecked, onToggle, disabled }) => {
  return (
    <label className={`ToggleSwitch ${disabled ? 'disabled' : ''}`}>
      <div className="ToggleSwitch__text-block">
        <span className="ToggleSwitch__text-block_label">{label}</span>
        <span className="ToggleSwitch__text-block_subtitle">
          {subtitle && subtitle}
        </span>
      </div>
      <input
        type="checkbox"
        checked={isChecked}
        className="ToggleSwitch__input"
        disabled={disabled}
        onChange={disabled ? null : onToggle}
      />
      <span
        className={`ToggleSwitch__slider ${isChecked ? 'checked' : ''} ${
          disabled ? 'disabled' : ''
        }`}
      >
        <span
          className={`ToggleSwitch__slider-before ${
            disabled ? 'disabled' : ''
          }`}
        />
      </span>
    </label>
  );
};

export default ToggleSwitch;
