import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import Flag from '../../icons/Flag';
import './Icon.css';

class Icon extends Component {
  getStyle() {
    const { label = false } = this.props;

    if (!label) return {};

    if (label === 'left') {
      return {
        marginLeft: '.5em',
      };
    } else if (label === 'right') {
      return {
        marginRight: '.5em',
      };
    }
    return {};
  }

  render() {
    const { ill, mod, className, ...props } = this.props;

    return (
      <i
        className={classNames(
          'Icon',
          `Icon--${ill}`,
          mod && `Icon--${ill}-${mod}`,
          // TODO: remove not BEM classes
          className
        )}
        style={this.getStyle()}
        {...props}
      />
    );
  }
}

Icon.propTypes = {
  type: PropTypes.oneOf(['user', 'further']),
};

export default Icon;

export const FurtherIcon = (props) => <Icon ill="further" {...props} />;
export const UserIcon = (props) => <Icon ill="user" {...props} />;
export const StatsIcon = (props) => <Icon ill="stats" {...props} />;
export const CodesIcon = (props) => <Icon ill="codes" {...props} />;
export const MapIcon = (props) => <Icon ill="map" {...props} />;
export const CompassIcon = (props) => <Icon ill="compass" {...props} />;
export const DragPointIcon = (props) => <Icon ill="drag-point" {...props} />;
export const LogoutIcon = (props) => <Icon ill="logout" {...props} />;
export const CirclesIcon = (props) => <Icon ill="circles" {...props} />;
export const ArrowHideIcon = (props) => <Icon ill="arrow-hide" {...props} />;
export const ErrorValidationIcon = () => <Icon ill="error-validation" />;
export const ShopIcon = (props) => <Icon ill="shop" {...props} />;
export const DoneIcon = (props) => <Icon ill="done" {...props} />;
export const SettingsIcon = (props) => <Icon ill="settings" {...props} />;
export const ChevronUpIcon = (props) => <Icon ill="chevron-up" {...props} />;
export const ChevronDownIcon = (props) => (
  <Icon ill="chevron-down" {...props} />
);
export const PatternIcon = (props) => <Icon ill="pattern" {...props} />;
export const CopyIcon = (props) => <Icon ill="copy" {...props} />;
export const CopyBlack = (props) => <Icon ill="copyBlack" {...props} />;
export const DeleteBinIcon = (props) => <Icon ill="deleteBin" {...props} />;
export const AddTextIcon = (props) => <Icon ill="add-text" {...props} />;
export const StarIcon = (props) => <Icon ill="star" {...props} />;
export const LockIcon = (props) => <Icon ill="lock" {...props} />;
export const ShareIcon = (props) => <Icon ill="share" {...props} />;
export const SettingsErrorIcon = (props) => (
  <Icon ill="settings-error" {...props} />
);
export const SpinnerIcon = (props) => <Icon ill="spinner" {...props} />;
export const ShopHiddenIcon = (props) => (
  <Icon ill="shop" mod="hidden" {...props} />
);
export const ShopWhiteIcon = (props) => (
  <Icon ill="shop" mod="white" {...props} />
);
export const LogoGrayIcon = (props) => <Icon ill="logo-gray" {...props} />;
export const DuplicateIcon = (props) => <Icon ill="duplicate" {...props} />;
export const ChatIcon = (props) => <Icon ill="chat" {...props} />;
export const FeedbackIcon = (props) => <Icon ill="feedback" {...props} />;
export const DocumentIcon = (props) => <Icon ill="document" {...props} />;
export const TrashIcon = (props) => <Icon ill="trash" {...props} />;
export const ExternalLink = (props) => <Icon ill="external-link" {...props} />;
export const StoreIcon = (props) => <Icon ill="store" {...props} />;
export const SurpriseMePhone = (props) => (
  <Icon ill="surprise-me-phone" {...props} />
);
export const ReferralUser = (props) => <Icon ill="referral-user" {...props} />;
export const PlusIcon = (props) => <Icon ill="plus" {...props} />;
export const ArtifactIcon = (props) => <Icon ill="artefact" {...props} />;

const IconBase = styled.span`
  width: ${(props) => (props.size ? props.size : '1em')};
  height: ${(props) => (props.size ? props.size : '1em')};
  display: inline-block;
  vertical-align: baseline;
`;

export const FlagVector = styled(Flag)`
  width: 100%;
  height: 100%;
  & > * {
    fill: #98a6ad;
  }
`;

export const FlagIcon = () => (
  <IconBase size=".8em">
    <FlagVector />
  </IconBase>
);
