import Api from '../../../functions/Api';

export async function FetchVoices(lang, questId, audioProvider) {
  let voices;

  try {
    let url = `quests/${questId}/listVoices/`;
    if (audioProvider) {
      const normalizedAudioProvider = audioProvider.toLowerCase();
      url += `?audioProvider=${normalizedAudioProvider}`;
    }

    const response = await Api.get(url, {
      headers: {
        'Accept-Language': lang,
      },
    });
    voices = response;
  } catch (e) {
    if (!voices) {
      return { statusCode: 404 };
    }
  }

  return voices;
}
