import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { MAX_IMAGE_SIZE } from '../../constants/limits';
import { Mobile, Desktop } from '../../functions/queries';
import './PictureDropzone.css';
import {
  ERROR_SHOW_TIME,
  ERR_TYPE,
  ERR_OVERFLOW,
  NO_ERR,
} from '../../constants/DropzoneErrors';

/**
 * Dropzone, accepts images and videos
 * @param {String} clasName - additional CSS class
 * @param {String} accept - selectors of file, accepting by dropzone in format type/extension (e.g. "image/png, video/*")
 * @param {Function} clearFiles - filter new files appeared in the dropzone
 * @param {Function} onDropAccepted - calls when new files appeared in the dropzone
 * @param {Array[String]} clickable - CSS classes of the children click on which will dispatch opening file dialog
 */
class PictureDropzone extends Component {
  state = {
    error: { type: NO_ERR },
  };

  /**
   * Shows error in ERROR_SHOW_TIME
   * @param {Object} error  - error to show in the textfield below content
   * @param {DropzoneError} error.type - type of the error
   * @param {Object} error.values - values for i18n, depend on type of the error
   */
  showError = (error) => {
    this.setState((prev) => ({ ...prev, error }));
    setTimeout(
      () => this.setState((prev) => ({ ...prev, error: { type: NO_ERR } })),
      ERROR_SHOW_TIME
    );
  };

  renderDefaultText = () => {
    const { titleText } = this.props;
    const { subText } = this.props;
    const { icon } = this.props;

    return (
      <div className="PictureDropzone__dropzone-items">
        {!icon && <div className="PictureDropzone__dropzone-icon" />}
        <div className="PictureDropzone__dropzone-text">
          <Mobile>
            <FormattedMessage
              id="EventImage.mobileText"
              defaultMessage="Click to select"
            />
          </Mobile>
          <Desktop>
            {titleText && icon ? (
              <>
                <div>{icon}</div>
                <div className="EventAR__dropzone-text--title">{titleText}</div>
              </>
            ) : (
              <FormattedMessage
                id="EventImage.desktopText"
                defaultMessage="Drag photos or click to select"
              />
            )}
          </Desktop>
          <span className="EventAR__dropzone-text--sub">{subText}</span>
        </div>
      </div>
    );
  };

  /**
   * Renders welcome text below dropzone
   */
  renderText = (error) => {
    if (!error) {
      return this.renderDefaultText();
    }
    switch (error.type) {
      case ERR_TYPE:
        return (
          <FormattedMessage
            id="EventImage.fileTypeErrorText"
            defaultMessage="Only pictures (jpeg, png, GIF) up to 10 MB"
          />
        );
      case ERR_OVERFLOW:
        return !this.props.allowVideos ? (
          <FormattedMessage
            id="EventImage.overflowErrorTextPictures"
            defaultMessage="No more images then {images}"
            values={error.values}
          />
        ) : (
          <FormattedMessage
            id="EventImage.overflowErrorText"
            defaultMessage="Limit reached. Up to {videos} videos and up to {images} images and GIFs"
            values={error.values}
          />
        );
      default:
        return this.renderDefaultText();
    }
  };

  render() {
    const {
      onDropAccepted,
      className,
      accept,
      clearFiles,
      clickable,
      children,
      subText,
      titleText,
      onClick,
      ...props
    } = this.props;

    const clearFilesFunc =
      clearFiles || ((files) => files.filter((f) => f.size <= MAX_IMAGE_SIZE));

    return (
      <Dropzone
        activeClassName="PictureDropzone__dropzone--active"
        rejectClassName="PictureDropzone__dropzone--error"
        accept={accept || 'image/*'}
        onDropAccepted={(files) => {
          files = clearFilesFunc(files);
          // TODO: filter other formats
          if (!files.length) {
            this.showError();
            return;
          }
          onDropAccepted(files);
        }}
        {...props}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div
              className={classNames('PictureDropzone__dropzone', className)}
              {...getRootProps({ onClick })}
            >
              <input {...getInputProps()} />
              <div
                className="PictureDropzone__children"
                onClick={(event) => {
                  const isClickable = clickable.filter((cssClass) =>
                    event.target.classList.contains(cssClass)
                  );

                  if (isClickable.length === 0) {
                    event.stopPropagation();
                  }
                }}
              >
                {children}
              </div>
              <div className="PictureDropzone__info">
                {this.renderText(this.state.error)}
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

export default PictureDropzone;
