import React from 'react';
import grey from '@material-ui/core/colors/grey';
import get from 'lodash/get';
import uuid from 'react-uuid';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

/**
 * JSS common styles for table rows
 * @type {Object}
 */
export const ROW_STYLES = {
  borderBottom: `1px solid ${grey[100]}`,
};

/**
 * Vertical and horizontal padding of the cell
 * @type {Array[Number]}
 */
export const GAP = [8, 8];

/**
 * Spread this to component containing any `TableCell` components
 * First column cell data will be aligned by left
 * Other data - by right
 * @type {Object}
 */
export const leftRightCellTextAlign = {
  '& td:first-child div': {
    textAlign: 'left',
  },
  '& td:not(:first-child) div': {
    textAlign: 'right',
  },
};

/**
 * JSS styles for `Table` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
    marginRight: '-25px',
  },
  tableFull: {
    minWidth: '100%',
  },
  row: {
    ...ROW_STYLES,
  },
}));

/**
 * Displays table
 * @param {Object} $
 * @param {String} $.className - additional CSS class name appending to the root element
 * @param {String} $.keys - `TableHeaderData::id` of the column with unique keys in `TableHeaderData::raw`(for React, if not specified - component may cause page freezes on redraws)
 * @param {React::Ref} $.rootRef - ref to the root element of the component
 * @param {React::Ref} $.tableRef - ref to the table element
 * @param {Array[TableHeaderData]} $.header - description of the header of the table
 * @param {Array[TableRowData]} $.rows - data for displaying in the row of the table (only cells with ids mentioned in `$.header` will be shown)
 * @param {Boolean} $.fullWidth - minimal width of the table will be set to `"100%"`
 * @param {Boolean} $.showHeader - will table header be displayed (`true` by default)
 */
export default function Table({
  keys,
  className,
  rootRef,
  tableRef,
  fullWidth = true,
  showHeader = true,
  header = [],
  rows = [],
  onScrolled = () => {},
  isPayouts = false,
}) {
  const styles = useStyles();
  return (
    <div
      className={classNames(styles.root, className)}
      onScroll={onScrolled}
      {...(rootRef ? { ref: rootRef } : {})}
    >
      <table
        className={classNames(fullWidth && styles.tableFull, styles.row)}
        cellSpacing={0}
        {...(tableRef ? { ref: tableRef } : {})}
      >
        {showHeader ? (
          <TableHeader className={styles.row} cells={header} />
        ) : null}
        <tbody>
          {rows.map((row, i) => {
            return (
              <TableRow
                key={keys && get(row, `${keys}.raw`) ? row[keys].raw : uuid()}
                className={i !== rows.length - 1 && styles.row}
                header={header}
                row={row}
                rows={rows}
                isPayouts={isPayouts}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
