export const isInputActive = (inputName, inputValues = {}) => {
  const {
    country,
    city,
    selectedCategories,
    stops,
    stories,
    duration,
    route,
    steps,
    photos,
    individStepsAudio,
    stepAudio,
    topic,
    startingPoint,
    products,
  } = inputValues;

  switch (inputName) {
    case 'country':
      return (
        (country && country !== '') ||
        (products?.[0]?.country?.name ?? '') !== ''
      );
    case 'city':
      return city !== '' || (products?.[0]?.city?.name ?? '') !== '';
    case 'selectedCategories':
      return selectedCategories?.length !== 0;
    case 'stops':
      return stops !== '';
    case 'stories':
      return stories !== '';
    case 'duration':
      return duration !== '';
    case 'route':
      return route !== '';
    case 'steps':
      return steps !== '';
    case 'photos':
      return photos !== '';
    case 'individStepsAudio':
      return individStepsAudio !== '';
    case 'stepAudio':
      return stepAudio !== '';
    case 'topic':
      return topic !== '';
    case 'startingPoint':
      return startingPoint !== '';
    default:
      return false;
  }
};
