import React from 'react';
import { getInputClasses } from './getInputClasses';
import { isInputActive } from './isInputActive';

export const FormField = ({
  id,
  value,
  onChange,
  onBlur,
  error,
  label,
  maxLength,
  inputClassName,
  textErrors,
  fieldErrors,
  isTextArea = false,
  topic = false,
  disabled = false,
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <div
        className={`GenerateAiTourForm__inputContainer ${
          React.isValidElement(error) && error.props.children
            ? 'GenerateAiTourForm__inputContainer_error'
            : ''
        }`}
      >
        {isTextArea ? (
          <textarea
            id={id}
            placeholder=" "
            className={`${
              topic ? 'GenerateAiTourForm__input_text' : ''
            } ${getInputClasses(
              inputClassName,
              value || isInputActive(id),
              textErrors,
              fieldErrors
            )}`}
            value={value}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            onBlur={() => onBlur(id)}
          />
        ) : (
          <input
            id={id}
            placeholder=" "
            className={getInputClasses(
              inputClassName,
              value || isInputActive(id),
              textErrors,
              fieldErrors
            )}
            value={value}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            onBlur={() => onBlur(id)}
          />
        )}
        <label htmlFor={id} className="GenerateAiTourForm__label">
          {label}
        </label>
        {error}
      </div>
    </div>
  );
};
