/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import { TEXT_TO_SPEACH_GOOGLE } from '../../constants/branding';
import Api from '../../functions/Api';
import Button from '../Button';
import { AddTextIcon, ChevronDownIcon, ChevronUpIcon, StarIcon } from '../Icon';
import ImportantNote from '../ImportantNote';
import Spinner from '../Spinner';
import ToggleSwitch from '../ToggleSwitch';
import Popup from '../Popup';
import { fetchUpdatedQuest, triggerUpdateQuestData } from '../../sagas/quests';
import { FetchCategory } from './FetchCategory';
import { FetchCity } from './FetchCity';
import { FetchCountry } from './FetchCountry';
import { performAction } from './PerformAction';
import { RadioButton } from './RadioButton';
import SpinnerPopup from './SpinnerPopup';
import { submitForm } from './SubmitForm';
import { ToggleButton } from './ToggleButton';
import { useOutsideAlerter } from './useOutsideAlerter';
import './GenerateAiTourForm.css';
import { FetchVoices } from './FetchVoices';
import { translations } from './translations';
import { DropdownInput } from './DropdownInputForm';
import { getInputClasses } from './helpers/getInputClasses';
import { isInputActive } from './helpers/isInputActive';
import { FormField } from './helpers/FormField';

const ErrorText = ({ message }) => (
  <span className="GenerateAiTourForm__errorText">{message}</span>
);

const GenerateAiTourForm = ({
  currentQuest,
  products,
  user,
  formatMessage,
  triggerUpdateQuestData,
  fetchUpdatedQuest,
}) => {
  const lang = user.locale;
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [stops, setStops] = useState('');
  const [steps, setSteps] = useState('');
  const [stepText, setStepText] = useState('');
  const [stepPhotosSourse, setStepPhotosSourse] = useState('');
  const [dropdownValues, setDropdownValues] = useState({
    textSourceMainForm: '',
    textSourceStops: '',
    textSourceStories: '',
    textSourceStepTexts: '',
    textSourceOtherTexts: '',
  });
  const [photos, setPhotos] = useState('');
  const [otherTexts, setOtherTexts] = useState('');
  const [category, setCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [manualPopupClosed, setManualPopupClosed] = useState(
    localStorage.getItem('manualPopupClosed') === 'true'
  );
  const [duration, setDuration] = useState('');
  const [stepAudio, setStepAudio] = useState('');
  const [individStepsAudio, setIndividStepsAudioAudio] = useState('');
  const [route, setRoute] = useState('');
  const [stories, setStories] = useState('');
  const [topic, setTopic] = useState('');
  const [startingPoint, setStartingPoint] = useState('');
  const [endingPoint, setEndingPoint] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [textErrors, setTextErrors] = useState({});
  const [isGenerated, setIsGenerated] = useState(false);
  const [stepTextOption, setStepTextOption] = useState('allSteps');
  const [stepPhotosOption, setStepPhotosOption] = useState('BasedStopName');
  const [voices, setVoices] = useState([]);
  const [voiceSearchQuery, setVoiceSearchQuery] = useState('');
  const [audioSource, setAudioSource] = useState(currentQuest.audioProvider);
  const [stepAudioOption, setStepAudioOption] = useState('allSteps');
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [isCountriesPopupVisible, setIsCountriesPopupVisible] = useState(false);
  const [isCitiesPopupVisible, setIsCitiesPopupVisible] = useState(false);
  const countriesWrapperRef = useRef(null);
  const citiesWrapperRef = useRef(null);
  const stepAudioRef = useRef(null);
  const otherCategoryRef = useRef(null);
  const [responseErrorMessage, setResponseErrorMessage] = useState('');
  const [isGenerationComplete, setIsGenerationComplete] = useState(false);
  const [isTourChecked, setTourChecked] = useState(false);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [isTextPopupOpen, setIsTextPopupOpen] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [selectedStepAudio, setSelectedStepAudio] = useState('');

  const [fieldErrors, setFieldErrors] = useState({
    countryInput: false,
    cityInput: false,
    stopsInput: false,
    storiesInput: false,
    durationInput: false,
    categoryInput: false,
    routeInput: false,
    stepsInput: false,
    photosInput: false,
    individStepsAudioInput: false,
    stepTextInput: false,
    otherTextsInput: false,
    stepAudioInput: false,
    topicInput: false,
    startingPointInput: false,
    endingPointInput: false,
  });

  const stepTextDropdownOptions = [
    formatMessage(translations.textOptionAllText),
    formatMessage(translations.textOptionNavigation),
  ];

  const stepPhotosSourseDropdownOptions = [
    formatMessage(translations.GoogleSource),
    formatMessage(translations.TripadvisorSource),
    formatMessage(translations.UnsplashSource),
  ];

  const stepAudioSourseDropdownOptions = ['Google', 'ElevenLabs'];

  const stepTextSourceDropdownOptions = ['ChatGPT', 'Perplexity'];

  const handleDropdownChange = (key, value) => {
    setDropdownValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const otherTextsDropdownOptions = [
    formatMessage(translations.otherTextOptionAllOtherText),
    formatMessage(translations.otherTextOptionIntroduction),
    formatMessage(translations.otherTextOptionConclusion),
    formatMessage(translations.otherTextOptionHighlights),
    formatMessage(translations.otherTextOptionOverview),
    formatMessage(translations.otherTextOptionTitle),
    formatMessage(translations.otherTextOptionDescription),
  ];

  const [blocksVisibility, setBlocksVisibility] = useState({
    Settings: true,
    Steps: false,
    Stories: false,
    StepTexts: false,
    StepPhotos: false,
    OtherTexts: false,
    Audio: false,
    Music: false,
  });

  const toggleBlockVisibility = (blockId) => {
    const savedTaskId = localStorage.getItem(`taskId-${currentQuest.id}`);
    if (savedTaskId) {
      checkTaskStatus(savedTaskId);
    }
    setBlocksVisibility((prevVisibility) => {
      const newVisibility = {
        Settings: false,
        Steps: false,
        Stories: false,
        StepTexts: false,
        StepPhotos: false,
        OtherTexts: false,
        Audio: false,
        Music: false,
      };
      if (blockId === 'Settings') {
        newVisibility.Settings = !prevVisibility.Settings;
      } else {
        newVisibility[blockId] = !prevVisibility[blockId];
      }

      return newVisibility;
    });
  };

  useOutsideAlerter(countriesWrapperRef, () =>
    setIsCountriesPopupVisible(false)
  );
  useOutsideAlerter(citiesWrapperRef, () => setIsCitiesPopupVisible(false));
  useOutsideAlerter(otherCategoryRef, () => setShowCategoryDropdown(false));

  useEffect(() => {
    const fetchCountries = async () => {
      const data = await FetchCountry(lang);
      setCountries(data);
    };

    if (!products[0]?.country?.name) {
      fetchCountries();
    }
  }, [lang, products]);

  useEffect(() => {
    if (currentQuest.generatedByAi) {
      const loadVoices = async () => {
        // Устанавливаем audioSource в зависимости от условия
        const source =
          stepAudioOption === 'individualSteps' &&
          selectedStepAudio &&
          currentQuest.audioProvider
            ? currentQuest.audioProvider
            : audioSource;

        const fetchedVoices = await FetchVoices(lang, currentQuest.id, source);
        setVoices(fetchedVoices || []);
      };
      loadVoices();
    }
  }, [
    currentQuest.id,
    currentQuest.generatedByAi,
    audioSource,
    stepAudioOption,
    selectedStepAudio,
    currentQuest.audioProvider,
    lang,
  ]);

  const handleCountrySelect = (selectedCountry) => {
    setCountry(selectedCountry.name);
    setSelectedCountryId(selectedCountry.id);
    setIsCountriesPopupVisible(false);
    setCity('');
  };
  const validateFieldWithLimit = (
    fieldName,
    value,
    maxLimit,
    isRequired = true,
    allowZero = false,
    applySpecialValidation = false
  ) => {
    let errorMessage = null;
    const numericValue = parseInt(value, 10);

    if (isRequired && value.trim() === '') {
      errorMessage = (
        <span className="GenerateAiTourForm__errorText">
          {formatMessage(translations.fieldRequired)}
        </span>
      );
    } else if (parseInt(value, 10) > maxLimit) {
      errorMessage = (
        <span className="GenerateAiTourForm__errorText">
          {formatMessage(translations.valueCannotBeMoreThan)} {maxLimit}.
        </span>
      );
    } else if (!allowZero && parseInt(value, 10) === 0) {
      errorMessage = (
        <span className="GenerateAiTourForm__errorText">
          {formatMessage(translations.valueCannotBeZero)}
        </span>
      );
    }

    if (
      applySpecialValidation &&
      (numericValue === 1 || numericValue === currentQuest.events.length)
    ) {
      errorMessage = (
        <span className="GenerateAiTourForm__errorText">
          {formatMessage(translations.validationIntroAndOutro)}
        </span>
      );
    }

    setTextErrors((prev) => ({ ...prev, [fieldName]: errorMessage }));
    setFieldErrors(errorMessage != null);
  };

  const [originalDuration, setOriginalDuration] = useState('');
  const [originalStops, setOriginalStops] = useState('');
  const [originalStories, setOriginalStories] = useState('');
  const [originalRoute, setOriginalRoute] = useState('');
  const [originalSelectedCategories, setOriginalSelectedCategories] = useState(
    ''
  );

  useEffect(() => {
    if (isGenerationComplete) {
      setOriginalDuration(duration);
      setOriginalStops(stops);
      setOriginalStories(stories);
      setOriginalRoute(route);
      setOriginalSelectedCategories([...selectedCategories]);
    }
  }, [isGenerationComplete]);

  const handleStopsChange = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9]/g, '');
    setStops(onlyNums);
    validateFieldWithLimit('stops', value, 50);
    if (onlyNums === originalStops) {
      setFieldsChanged(false);
    } else if (isGenerationComplete) {
      setFieldsChanged(true);
    }
  };

  const handleStartingPointChange = (setter) => (event) => {
    const { value } = event.target;
    const validatedValue = value.replace(/[^a-zA-Zа-яА-ЯёЁ\s]/g, '');
    setter(validatedValue);
  };

  const handleEndingPointChange = (setter) => (event) => {
    const { value } = event.target;
    const validatedValue = value.replace(/[^a-zA-Zа-яА-ЯёЁ\s]/g, '');
    setter(validatedValue);
  };

  const handleStoriesChange = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9]/g, '');
    setStories(onlyNums);
    validateFieldWithLimit('stories', value, 50, true, true);
    if (onlyNums === originalStories) {
      setFieldsChanged(false);
    } else if (isGenerationComplete) {
      setFieldsChanged(true);
    }
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9]/g, '');
    setDuration(onlyNums);
    validateFieldWithLimit('duration', value, 100);
    if (onlyNums === originalDuration) {
      setFieldsChanged(false);
    } else if (isGenerationComplete) {
      setFieldsChanged(true);
    }
  };

  const handleStepAudioChange = (valueOrEvent) => {
    const value = valueOrEvent.target
      ? valueOrEvent.target.value
      : valueOrEvent;

    if (value === '') {
      setStepAudio('');
    } else {
      setStepAudio(value);
    }
  };

  const handleRouteChange = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9]/g, '');
    setRoute(onlyNums);
    validateFieldWithLimit('route', value, 100);
    if (onlyNums === originalRoute) {
      setFieldsChanged(false);
    } else if (isGenerationComplete) {
      setFieldsChanged(true);
    }
  };

  const [totalSteps, setTotalSteps] = useState(currentQuest.events.length);

  const handleStepsChange = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9]/g, '');
    setSteps(onlyNums);
    validateFieldWithLimit('steps', onlyNums, totalSteps, false, false, true);
  };

  const handlePhotosChange = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9]/g, '');
    setPhotos(onlyNums);
    validateFieldWithLimit('photos', onlyNums, totalSteps, false, false, true);
  };

  const handleIndividStepsAudioChange = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9]/g, '');
    setIndividStepsAudioAudio(onlyNums);
    validateFieldWithLimit('individStepsAudio', onlyNums, totalSteps);
  };

  const handleChangeCountry = (e) => {
    const newCountryValue = e.target.value;
    setCountry(newCountryValue);

    setSelectedCountryId(null);
    setCity('');

    if (!newCountryValue) {
      setIsCitiesPopupVisible(false);
    } else {
      setIsCountriesPopupVisible(true);
    }
  };

  useEffect(() => {
    if (selectedCountryId) {
      const fetchCities = async () => {
        const data = await FetchCity(lang, selectedCountryId);
        setCities(data);
      };

      fetchCities();
    } else {
      setCities([]);
    }
  }, [lang, selectedCountryId]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await FetchCategory(lang);
        if (!data || data.statusCode === 404) {
          return;
        }
        setCategory(data);
      } catch (error) {
        console.error('error', error);
      }
    };

    fetchCategories();
  }, [lang]);

  const [categoryTouched, setCategoryTouched] = useState(false);
  const handleSelectionChange = (categoryObj, isChecked) => {
    setCategoryTouched(true);
    let newSelectedCategories;
    if (isChecked) {
      newSelectedCategories = selectedCategories.filter(
        (category) => category.id !== categoryObj.id
      );
    } else {
      if (selectedCategories.length < 2) {
        newSelectedCategories = [...selectedCategories, categoryObj];
      } else {
        return;
      }
    }
    setSelectedCategories(newSelectedCategories);

    const originalIds = Array.isArray(originalSelectedCategories)
      ? originalSelectedCategories.map((cat) => cat.id).sort()
      : [];
    const newIds = Array.isArray(newSelectedCategories)
      ? newSelectedCategories.map((cat) => cat.id).sort()
      : [];
    const isSameSelection =
      originalIds.length === newIds.length &&
      originalIds.every((value, index) => value === newIds[index]);

    if (isGenerationComplete) {
      setFieldsChanged(!isSameSelection);
    }
  };

  useEffect(() => {
    if (categoryTouched) {
      setFieldErrors((prev) => ({
        ...prev,
        categoryInput: selectedCategories.length === 0,
      }));
    }
  }, [selectedCategories, categoryTouched]);

  const handleCitySelect = (selectedCity) => {
    setCity(selectedCity.name);
    setSelectedCityId(selectedCity.id);
    setIsCitiesPopupVisible(false);
  };

  const filteredCountries = countries.filter((c) =>
    c.name.toLowerCase().startsWith(country.toLowerCase())
  );

  const filteredCities = cities.filter((c) =>
    c.name.toLowerCase().startsWith(city.toLowerCase())
  );

  const inputValues = {
    country: country || '',
    city: city || '',
    selectedCategories: selectedCategories || [],
    stops: stops || '',
    stories: stories || '',
    duration: duration || '',
    route: route || '',
    steps: steps || '',
    photos: photos || '',
    individStepsAudio: individStepsAudio || '',
    stepAudio: stepAudio || '',
    topic: topic || '',
    startingPoint: startingPoint || '',
    endingPoint: endingPoint || '',
    products: products || [],
  };

  const validateField = (fieldName) => {
    switch (fieldName) {
      case 'country':
        setFieldErrors((prev) => ({
          ...prev,
          countryInput: country.trim() === '',
        }));
        break;
      case 'city':
        setFieldErrors((prev) => ({
          ...prev,
          cityInput: city.trim() === '',
        }));
        break;
      case 'stops':
        const isStopsInvalid =
          stops.trim() === '' || parseInt(stops, 10) > 50 || stops.trim() === 0;
        setFieldErrors((prev) => ({
          ...prev,
          stopsInput: isStopsInvalid,
        }));
        break;
      case 'stories':
        const isStoriesInvalid =
          stories.trim() === '' || parseInt(stories, 10) > 50;
        setFieldErrors((prev) => ({
          ...prev,
          storiesInput: isStoriesInvalid,
        }));
        break;
      case 'duration':
        const isDurationInvalid =
          duration.trim() === '' ||
          parseInt(duration, 10) > 100 ||
          duration.trim() === 0;
        setFieldErrors((prev) => ({
          ...prev,
          durationInput: isDurationInvalid,
        }));
        break;
      case 'route':
        const isRouteInvalid =
          route.trim() === '' ||
          parseInt(route, 10) > 100 ||
          route.trim() === 0;
        setFieldErrors((prev) => ({
          ...prev,
          routeInput: isRouteInvalid,
        }));
        break;
      case 'steps':
        const isStepsInvalid = parseInt(steps, 10) > totalSteps;
        setFieldErrors((prev) => ({
          ...prev,
          stepsInput: isStepsInvalid,
        }));
        break;
      case 'photos':
        const isPhotosInvalid = parseInt(photos, 10) > totalSteps;
        setFieldErrors((prev) => ({
          ...prev,
          photosInput: isPhotosInvalid,
        }));
        break;
      case 'individStepsAudio':
        const isIndividStepsAudioInvalid =
          parseInt(individStepsAudio, 10) > totalSteps ||
          textErrors['individStepsAudio'].props.children;
        setFieldErrors((prev) => ({
          ...prev,
          individStepsAudioInput: isIndividStepsAudioInvalid,
        }));
        break;
      case 'stepText':
        setFieldErrors((prev) => ({
          ...prev,
          stepTextInput: stepText.trim() === '',
        }));
        break;
      case 'category':
        setFieldErrors((prev) => ({
          ...prev,
          categoryInput: selectedCategories.length === 0,
        }));
        break;
      case 'otherTexts':
        setFieldErrors((prev) => ({
          ...prev,
          otherTextsInput: otherTexts.trim() === '',
        }));
        break;
      case 'topic':
        setFieldErrors((prev) => ({
          ...prev,
          topicInput: topic.trim() === '',
        }));
        break;
      case 'startingPoint':
        setFieldErrors((prev) => ({
          ...prev,
          startingPointInput: startingPoint.trim() === '',
        }));
        break;
      case 'endingPoint':
        setFieldErrors((prev) => ({
          ...prev,
          endingPointInput: endingPoint.trim() === '',
        }));
        break;
      default:
        break;
    }
  };

  const handleChange = (setter) => (e) => setter(e.target.value);

  const handleBlur = (fieldName, value, maxLimit) => {
    validateField(fieldName);
    if (fieldName === 'stories') {
      validateFieldWithLimit(fieldName, value, maxLimit, true, true);
    } else if (['stops', 'duration', 'route'].includes(fieldName)) {
      validateFieldWithLimit(fieldName, value, maxLimit);
    }
  };

  const areMainFieldsFilled = useMemo(() => {
    const countryFilled = country || (products[0]?.country?.name ?? '');
    const cityFilled = city || (products[0]?.city?.name ?? '');

    const stopsFilled = stops && Number(stops) > 0;
    const durationFilled = duration && Number(duration) > 0;
    const routeFilled = route && Number(route) > 0;
    const storiesFilled = stories;

    return (
      countryFilled &&
      cityFilled &&
      stopsFilled &&
      durationFilled &&
      routeFilled &&
      storiesFilled &&
      category.length > 0
    );
  }, [country, city, stops, duration, route, stories, category, products]);

  useEffect(() => {
    setIsGenerated(areMainFieldsFilled && topic);
  }, [areMainFieldsFilled, topic]);

  const [generationProgress, setGenerationProgress] = useState(null);
  const [aitourId, setAitourId] = useState(null);
  const [generationInitiated, setGenerationInitiated] = useState(false);
  const [initialProgressLoaded, setInitialProgressLoaded] = useState(false);

  const fetchGenerationProgress = async () => {
    if (!aitourId || isGenerationComplete || manualPopupClosed) return;

    try {
      const data = await Api.get(
        `quests/generationProgress/?aitourId=${aitourId}`
      );
      setGenerationProgress(data.generationProgress);
      setInitialProgressLoaded(true);

      if (data.generationProgress === 100) {
        localStorage.setItem(`isGenerationRunning-${currentQuest.id}`, 'false');
        setIsGenerationComplete(true);
        setFieldsChanged(false);
        setGenerationInitiated(false);
        setManualPopupClosed(false);
      } else if (data.generationProgress === 400) {
        if (!manualPopupClosed) {
          setIsGenerationComplete(false);
          setGenerationInitiated(true);
        }
      }
    } catch (error) {
      console.error('Error fetching generation progress:', error);
    }
  };

  useEffect(() => {
    if (currentQuest && currentQuest.aitourId) {
      setAitourId(currentQuest.aitourId);
      localStorage.setItem(
        `aitourId-${currentQuest.id}`,
        currentQuest.aitourId.toString()
      );
    } else {
      const savedAitourId = localStorage.getItem(`aitourId-${currentQuest.id}`);
      if (savedAitourId) {
        setAitourId(savedAitourId);
      }
    }
  }, [currentQuest]);
  const [generationTriggered, setGenerationTriggered] = useState(false);

  const handleSubmit = async () => {
    setIsGenerationComplete(false);
    setGenerationProgress(0);
    setGenerationInitiated(false);
    triggerUpdateQuestData(currentQuest.id);

    const textSource =
      dropdownValues.textSourceMainForm &&
      dropdownValues.textSourceMainForm !== ''
        ? dropdownValues.textSourceMainForm.toLowerCase()
        : 'chatgpt';

    localStorage.removeItem('aitourId');
    localStorage.removeItem('isGenerationRunning');
    localStorage.removeItem('needsReload');
    localStorage.removeItem('reloadCompleted');

    try {
      let countryIdToSend = selectedCountryId;
      let cityIdToSend = selectedCityId;

      if (!selectedCountryId && products.length > 0) {
        countryIdToSend = products[0]?.country?.id;
      }
      if (!selectedCityId && products.length > 0) {
        cityIdToSend = products[0]?.city?.id;
      }
      const response = await submitForm(
        countryIdToSend,
        cityIdToSend,
        selectedCategories,
        duration,
        route,
        stops,
        stories,
        textSource,
        topic,
        isTourChecked,
        startingPoint,
        endingPoint,
        currentQuest
      );

      if (!response || (!response.id && !currentQuest.aitourId)) {
        throw new Error('request failed');
      }
      // console.log('handleSubmit-responseData', response);
      triggerUpdateQuestData(currentQuest.id);
      const aitourIdToUse = currentQuest.aitourId
        ? currentQuest.aitourId
        : response.id;
      setAitourId(aitourIdToUse);
      localStorage.setItem(
        `aitourId-${currentQuest.id}`,
        aitourIdToUse.toString()
      );
      localStorage.setItem(`isGenerationRunning-${currentQuest.id}`, 'true');
    } catch (error) {
      console.error('Error:', error);
    }
    setGenerationInitiated(true);
    setGenerationTriggered(true);
    localStorage.setItem('generationTriggered', 'true');
  };

  useEffect(() => {
    const generationInitiated =
      localStorage.getItem('generationTriggered') === 'true';
    setGenerationTriggered(generationInitiated);
  }, []);

  useEffect(() => {
    const savedAitourId = localStorage.getItem(`aitourId-${currentQuest.id}`);

    const isGenerationRunning =
      localStorage.getItem(`isGenerationRunning-${currentQuest.id}`) === 'true';
    const needsReload =
      localStorage.getItem(`needsReload-${currentQuest.id}`) === 'true';
    const reloadCompleted =
      localStorage.getItem(`reloadCompleted-${currentQuest.id}`) === 'true';

    if (isGenerationRunning && needsReload && !reloadCompleted) {
      localStorage.setItem(`reloadCompleted-${currentQuest.id}`, 'true');
    } else {
      localStorage.removeItem(`needsReload-${currentQuest.id}`);
      localStorage.removeItem(`reloadCompleted-${currentQuest.id}`);
    }

    if (savedAitourId) {
      setAitourId(savedAitourId);
      if (isGenerationRunning) {
        setGenerationInitiated(true);
        setTimeout(() => {
          fetchGenerationProgress();
        }, 500);
      }
    }
  }, [aitourId, currentQuest.id]);

  useEffect(() => {
    if (aitourId && !isGenerationComplete) {
      setTimeout(() => {
        fetchGenerationProgress();
      }, 500);

      const intervalId = setInterval(() => {
        fetchGenerationProgress();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [aitourId, isGenerationComplete]);

  useEffect(() => {
    if (
      stepAudioOption === 'allSteps' ||
      (stepAudioOption === 'allSteps' && audioSource)
    ) {
      setStepAudio('');
      setIndividStepsAudioAudio('');
    }
  }, [stepAudioOption, audioSource]);

  useEffect(() => {
    const fetchTourDetails = async () => {
      if (currentQuest.generatedByAi && currentQuest.aitourId) {
        try {
          const data = await Api.get(
            `quests/aitours/${currentQuest.aitourId}/`
          );
          setStops(data.numberOfStops.toString());
          setStories(data.numberOfStories.toString());
          setDuration(data.duration.toString());
          setRoute(data.routeLength.toString());
          setTopic(data.additionalRequirements.toString() || '');
          setStartingPoint(data.startingPoint?.toString() || '');
          setEndingPoint(data.endingPoint?.toString() || '');
          setTotalSteps(totalSteps);
          if (stepAudioOption === 'individualSteps') {
            setStepAudio(currentQuest.voice.toString());
            setSelectedStepAudio(currentQuest.voice);
          }
          const findCategoryTitleById = (id) => {
            for (const cat of category) {
              for (const subcat of cat.subcategories) {
                if (subcat.id === id) return subcat.title;
              }
            }
          };

          const selectedCategoriesWithNames = data.subcategories.map(
            (subcatId) => {
              return {
                id: subcatId,
                title: findCategoryTitleById(subcatId),
              };
            }
          );

          setSelectedCategories(selectedCategoriesWithNames);
        } catch (error) {
          console.error('Failed to fetch tour details:', error);
        }
      }
    };

    fetchTourDetails().then(() => {
      setFieldsChanged(false);
    });
  }, [
    currentQuest.generatedByAi,
    currentQuest.aitourId,
    category,
    stepAudioOption,
  ]);

  const [taskId, setTaskId] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [checkTaskStatusError, setCheckTaskStatusError] = useState(false);

  useEffect(() => {
    if (taskId) {
      localStorage.setItem(`taskId-${currentQuest.id}`, taskId);
    }
  }, [taskId, currentQuest.id]);

  const checkTaskStatus = async (taskId) => {
    if (!taskId) return;

    try {
      const response = await Api.get(`quests/taskResult/?taskId=${taskId}`);
      const status = response.status;
      setTaskStatus(status);
      setCheckTaskStatusError(
        status === 'FAILURE' || response.result === 'False'
      );

      if (status === 'PENDING') {
        setIsPopupVisible(true);
        setFieldsChanged(false);
      } else {
        setIsPopupVisible(false);
        if (status === 'SUCCESS' || status === 'FAILURE') {
          localStorage.removeItem(`taskId-${currentQuest.id}`);
          if (response && response.result !== null) {
            setResponseErrorMessage(response.result);
            // console.log(response.result);
            console.log('responseErrorMessage', responseErrorMessage);
          }
        }
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      setIsPopupVisible(false);
      setCheckTaskStatusError(true);
    }
  };

  useEffect(() => {
    let intervalId;
    const currentTaskId = localStorage.getItem(`taskId-${currentQuest.id}`);
    if (currentTaskId) {
      checkTaskStatus(currentTaskId);
      intervalId = setInterval(() => checkTaskStatus(currentTaskId), 30000);
    }

    return () => clearInterval(intervalId);
  }, [currentQuest.id, taskId]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const showPopup = () => {
    setIsPopupOpen(true);
  };
  const handleActionClick = async (actionType, options = {}) => {
    const result = await performAction(currentQuest, actionType, options);
    triggerUpdateQuestData(currentQuest.id);
    if (result && result.taskId) {
      setTaskId(result.taskId);
      setTaskStatus('PENDING');
    }
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (taskStatus === 'SUCCESS') {
      localStorage.setItem('needsReload', 'true');
      setFieldsChanged(false);
      setTimeout(() => {
        fetchUpdatedQuest(currentQuest.id);
      }, 1000);
    } else if (taskStatus === 'PENDING') {
      triggerUpdateQuestData(currentQuest.id);
      console.log('Task status is PENDING. Awaiting completion...');
    } else if (taskStatus === 'ERROR') {
      console.log('Task encountered an ERROR. Check for details...');
    }
  }, [taskStatus]);

  useEffect(() => {
    const savedTaskId = localStorage.getItem(
      `taskId-${currentQuest.id}`,
      taskId
    );
    if (savedTaskId) {
      setTaskId(savedTaskId);
      checkTaskStatus(savedTaskId);
    }
  }, []);

  useEffect(() => {
    if (taskStatus === 'SUCCESS' || taskStatus === 'ERROR') {
      localStorage.removeItem(`taskId-${currentQuest.id}`, taskId);
      setTaskId(null);
    }
  }, [taskStatus]);

  useEffect(() => {
    if (taskStatus === 'PENDING') {
      setIsPopupVisible(true);
    } else {
      setIsPopupVisible(false);
    }
  }, [taskStatus]);

  useEffect(() => {
    if (isGenerationComplete && generationTriggered) {
      const alreadyReloaded =
        localStorage.getItem('reloadCompleted') === 'true';
      if (!alreadyReloaded) {
        console.log('Setting needsReload to true and reloading...');
        localStorage.setItem('needsReload', 'true');
        localStorage.removeItem('generationTriggered');
        fetchUpdatedQuest(currentQuest.id);
      } else {
        console.log('Already reloaded once. No further reload needed.');
      }
    }
  }, [isGenerationComplete, generationTriggered]);

  useEffect(() => {
    const needsReload = localStorage.getItem('needsReload') === 'true';
    if (needsReload) {
      localStorage.removeItem('needsReload');
      localStorage.setItem('reloadCompleted', 'true');
    } else {
      const alreadyReloaded =
        localStorage.getItem('reloadCompleted') === 'true';
      if (alreadyReloaded) {
        console.log('Reload was completed. Clearing reloadCompleted flag.');
        localStorage.removeItem('reloadCompleted');
      }
    }
  }, []);
  const popupCityRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);

  const searchCities = async (searchText) => {
    const countryId = selectedCountryId;
    try {
      const response = await Api.get(
        `v2/cities/search/?country=${countryId}&preorder=true&published=false&query=${searchText}`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );

      if (response && Array.isArray(response)) {
        setSearchResults(response);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error('error', error);
      setSearchResults([]);
    } finally {
    }
  };

  useEffect(() => {
    if (city.length > 0 && selectedCountryId) {
      searchCities(city);
    } else {
      setSearchResults([]);
    }
  }, [city, selectedCountryId]);

  const [content, setContent] = useState(null);

  useEffect(() => {
    let updatedContent;

    if (fieldsChanged) {
      updatedContent = (
        <ImportantNote
          title={
            <span style={{ display: 'block' }}>
              {formatMessage(translations.makeChangesTitle)}
            </span>
          }
          items={[
            <div key={uuid()}>
              {formatMessage(translations.makeChangesDescription)}
            </div>,
          ]}
          theme="helper-error"
        />
      );
    } else if (isGenerationComplete) {
      updatedContent = (
        <ImportantNote
          title={
            <span style={{ display: 'block' }}>
              {formatMessage(translations.tourGeneratedTitle)}
            </span>
          }
          items={[
            <div key={uuid()}>
              {formatMessage(translations.tourGeneratedDescription)}
            </div>,
          ]}
          theme="done"
        />
      );
    } else {
      updatedContent = (
        <ImportantNote
          title={
            <span style={{ display: 'block' }}>
              {formatMessage(translations.fillInTheFieldsTitle)}
            </span>
          }
          items={[
            <div key={uuid()}>
              {formatMessage(translations.fillInTheFieldsDescription)}
            </div>,
          ]}
          theme="helper"
        />
      );
    }

    setContent(updatedContent);
  }, [fieldsChanged, isGenerationComplete]);

  const [showChangesPopup, setShowChangesPopup] = useState(false);

  const handleManualClose = () => {
    setManualPopupClosed(true);
    setIsGenerationComplete(false);
    setGenerationInitiated(false);
    localStorage.setItem('manualPopupClosed', 'true');
  };

  const resetManualClose = () => {
    setManualPopupClosed(false);
    localStorage.removeItem('manualPopupClosed');
  };

  useEffect(() => {
    if (localStorage.getItem('manualPopupClosed') !== 'true' && aitourId) {
      fetchGenerationProgress();
    }
  }, [aitourId]);

  const handleSubmitButton = () => {
    if ((isGenerationComplete && fieldsChanged) || isGenerationComplete) {
      setShowChangesPopup(true);
    } else {
      resetManualClose();
      setTimeout(() => {
        handleSubmit();
      }, 0);
    }
  };
  const [hasErrors, setHasErrors] = useState(false);

  const hasValidationErrors = () => {
    const errorMessages = Object.values(textErrors);
    const hasErrors = errorMessages.some(
      (message) => message !== null && message !== ''
    );

    return hasErrors;
  };

  useEffect(() => {
    setHasErrors(hasValidationErrors());
  }, [
    textErrors,
    country,
    city,
    stops,
    duration,
    route,
    stories,
    category,
    topic,
    startingPoint,
    endingPoint,
  ]);

  const [textInputValue, setTextInputValue] = useState('');
  const [isTextConfirmed, setIsTextConfirmed] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [initialTextValue, setInitialTextValue] = useState('');
  const [confirmedText, setConfirmedText] = useState('');

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value.length <= 10000) {
      setTextInputValue(value);
    }
  };

  const handleConfirm = () => {
    setIsTextConfirmed(true);
    setConfirmedText(textInputValue);
    setIsTextPopupOpen(false);
  };

  const handleOpenTextPopup = () => {
    setInitialTextValue(confirmedText);
    setTextInputValue(confirmedText);
    setIsTextPopupOpen(true);
  };

  const handleCloseTextPopup = () => {
    if (textInputValue !== initialTextValue && textInputValue !== '') {
      setIsTextPopupOpen(false);
      setIsConfirmPopupOpen(true);
    } else {
      setIsTextPopupOpen(false);
    }
  };

  const handleConfirmClose = () => {
    setIsConfirmPopupOpen(false);
    setTextInputValue(confirmedText);
  };

  const handleCancelClose = () => {
    setIsConfirmPopupOpen(false);
    setIsTextPopupOpen(true);
  };
  const [activeDropdownId, setActiveDropdownId] = useState(null);

  const handleToggleDropdown = (id) => {
    setActiveDropdownId((prevId) => (prevId === id ? null : id));
  };

  useEffect(() => {
    if (!startingPoint) {
      setTourChecked(false);
      setEndingPoint('');
    }
  }, [startingPoint]);

  useEffect(() => {
    if (
      stepAudioOption === 'individualSteps' &&
      selectedStepAudio &&
      currentQuest.audioProvider
    ) {
      setAudioSource(currentQuest.audioProvider);
    }
  }, [stepAudioOption, selectedStepAudio, currentQuest.audioProvider]);

  return (
    <div className="GenerateAiTourForm">
      <button
        className="GenerateAiTourForm__button-fields"
        onClick={() => toggleBlockVisibility('Settings')}
      >
        {formatMessage(translations.mainSettings)}
        {blocksVisibility.Settings ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </button>

      {blocksVisibility.Settings && (
        <div className="GenerateAiTourForm__block">
          <SpinnerPopup
            showGenerationProgress
            formatMessage={formatMessage}
            isOpen={
              generationInitiated && initialProgressLoaded && !manualPopupClosed
            }
            handleCloseClick={isGenerationComplete}
            handleManualClose={handleManualClose}
            generationProgress={generationProgress}
          />
          {content}
          <div className="GenerateAiTourForm__fields-block">
            <div style={{ position: 'relative' }}>
              <div className="GenerateAiTourForm__inputContainer">
                <input
                  disabled
                  className="GenerateAiTourForm__input GenerateAiTourForm__input_active"
                  value={formatMessage(translations.englishLanguage)}
                  maxLength={1000}
                />
              </div>
            </div>
            {/* Блок для поля "Страны" */}
            <div ref={countriesWrapperRef} style={{ position: 'relative' }}>
              {' '}
              <div
                className={`GenerateAiTourForm__inputContainer ${
                  fieldErrors.countryInput
                    ? 'GenerateAiTourForm__inputContainer_error'
                    : ''
                }`}
              >
                <input
                  placeholder=" "
                  value={products[0]?.country?.name ?? country}
                  disabled={isGenerated || !!products[0]?.country?.name}
                  className={getInputClasses(
                    'countryInput',
                    country || isInputActive('country', inputValues),
                    textErrors,
                    fieldErrors
                  )}
                  onChange={handleChangeCountry}
                  onBlur={() => validateField('country')}
                  onFocus={() => setIsCountriesPopupVisible(true)}
                />

                {!isGenerated && !products[0]?.country?.name && (
                  <label
                    htmlFor="countryInput"
                    className="GenerateAiTourForm__label"
                  >
                    {formatMessage(translations.countryPlaceholder)}
                  </label>
                )}
                {fieldErrors.countryInput && (
                  <ErrorText message={'This field is required'} />
                )}
                {isCountriesPopupVisible && (
                  <div className="GenerateAiTourForm__popup">
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                      {filteredCountries.map((c) => (
                        <li
                          key={c.id}
                          className="GenerateAiTourForm__listItem"
                          style={{ cursor: 'pointer' }}
                          onMouseDown={() => handleCountrySelect(c)}
                        >
                          {c.name}
                        </li>
                      ))}
                    </ul>
                    {filteredCountries.length === 0 && (
                      <div className="GenerateAiTourForm__popup_spinner">
                        <Spinner />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Блок для поля "Города" */}
            <div ref={citiesWrapperRef} style={{ position: 'relative' }}>
              <div
                className={`GenerateAiTourForm__inputContainer ${
                  fieldErrors.cityInput
                    ? 'GenerateAiTourForm__inputContainer_error'
                    : ''
                }`}
              >
                <input
                  placeholder=" "
                  value={products[0]?.city?.name ?? city}
                  disabled={
                    !selectedCountryId ||
                    isGenerated ||
                    !!products[0]?.city?.name
                  }
                  className={getInputClasses(
                    'cityInput',
                    city || isInputActive('city', inputValues),
                    textErrors,
                    fieldErrors
                  )}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  onBlur={() => validateField('city')}
                  onFocus={() => setIsCitiesPopupVisible(true)}
                />
                {!isGenerated && !products[0]?.city?.name && (
                  <label
                    htmlFor="cityInput"
                    className="GenerateAiTourForm__label"
                  >
                    {formatMessage(translations.cityPlaceholder)}
                  </label>
                )}
                {fieldErrors.cityInput && (
                  <ErrorText message={'This field is required'} />
                )}
                {isCitiesPopupVisible && (
                  <div ref={popupCityRef} className="GenerateAiTourForm__popup">
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                      {(searchResults.length > 0
                        ? searchResults
                        : filteredCities
                      ).map((c) => (
                        <li
                          key={c.id}
                          className="GenerateAiTourForm__listItem"
                          style={{ cursor: 'pointer' }}
                          onMouseDown={() => handleCitySelect(c)}
                        >
                          {c.name}
                        </li>
                      ))}
                    </ul>
                    {searchResults.length === 0 && city.length > 0 && (
                      <div className="GenerateAiTourForm__popup_spinner">
                        <Spinner />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Блок для поля "Категрий" */}
            <div ref={otherCategoryRef} style={{ position: 'relative' }}>
              <div
                className={`GenerateAiTourForm__inputContainer ${
                  fieldErrors.categoryInput
                    ? 'GenerateAiTourForm__inputContainer_error'
                    : ''
                }`}
                onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
              >
                <input
                  readOnly
                  className={getInputClasses(
                    'categoryInput',
                    selectedCategories > 0 ||
                      isInputActive('selectedCategories', inputValues),
                    textErrors,
                    fieldErrors
                  )}
                  value={selectedCategories.map((cat) => cat.title).join(', ')}
                  placeholder=" "
                  onBlur={() => validateField('category')}
                />
                <label
                  htmlFor="categoryInput"
                  className="GenerateAiTourForm__label"
                >
                  {formatMessage(translations.categoryPlaceholder)}
                </label>
                {fieldErrors.categoryInput && (
                  <ErrorText message={'This field is required'} />
                )}
                <div className="GenerateAiTourForm__chevron">
                  {!showCategoryDropdown ? (
                    <ChevronDownIcon />
                  ) : (
                    <ChevronUpIcon />
                  )}
                </div>
                {showCategoryDropdown && (
                  <div className="GenerateAiTourForm__popup">
                    <ul style={{ listStyle: 'none', padding: '0' }}>
                      {category
                        .filter((cat) => cat.id !== 10)
                        .map(
                          (cat) =>
                            cat.subcategories &&
                            cat.subcategories.length > 0 && (
                              <React.Fragment key={cat.id}>
                                <li>
                                  <div className="GenerateAiTourForm__listItem-category">
                                    {cat.title}
                                  </div>
                                  <ul>
                                    {cat.subcategories.map((sub) => (
                                      <li
                                        key={sub.id}
                                        className="GenerateAiTourForm__listItem"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          handleSelectionChange(
                                            { id: sub.id, title: sub.title },
                                            selectedCategories.some(
                                              (cat) => cat.id === sub.id
                                            )
                                          )
                                        }
                                      >
                                        <input
                                          readOnly
                                          className="GenerateAiTourForm__checkbox-custom"
                                          type="checkbox"
                                          checked={selectedCategories.some(
                                            (cat) => cat.id === sub.id
                                          )}
                                        />{' '}
                                        {sub.title}
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              </React.Fragment>
                            )
                        )}
                    </ul>

                    {category.length === 0 && (
                      <div className="GenerateAiTourForm__popup_spinner">
                        <Spinner />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <FormField
              isTextArea
              id="startingPointInput"
              value={startingPoint}
              placeholder={formatMessage(translations.startingPoint)}
              error={textErrors.startingPoint}
              label={formatMessage(translations.startingPoint)}
              inputClassName="GenerateAiTourForm__input GenerateAiTourForm__input_text"
              maxLength={100}
              textErrors={textErrors}
              fieldErrors={fieldErrors}
              onChange={handleStartingPointChange(setStartingPoint)}
              onBlur={() => handleBlur('startingPoint')}
            />

            <FormField
              isTextArea
              id="endingPointInput"
              disabled={!startingPoint}
              value={endingPoint}
              placeholder={formatMessage(translations.endingPoint)}
              error={textErrors.endingPoint}
              label={formatMessage(translations.endingPoint)}
              inputClassName="GenerateAiTourForm__input GenerateAiTourForm__input_text"
              maxLength={100}
              textErrors={textErrors}
              fieldErrors={fieldErrors}
              onChange={handleEndingPointChange(setEndingPoint)}
              onBlur={() => handleBlur('endingPoint')}
            />
            <FormField
              id="stopsInput"
              value={stops}
              placeholder={formatMessage(translations.numberOfStopsPlaceholder)}
              error={textErrors.stops}
              label={formatMessage(translations.numberOfStopsPlaceholder)}
              maxLength={50}
              inputClassName="stopsInput"
              textErrors={textErrors}
              fieldErrors={fieldErrors}
              onChange={handleStopsChange}
              onBlur={handleBlur}
            />

            <FormField
              id="storiesInput"
              value={stories}
              placeholder={formatMessage(
                translations.numberOfStoriesPlaceholder
              )}
              error={textErrors.stories}
              label={formatMessage(translations.numberOfStoriesPlaceholder)}
              maxLength={50}
              inputClassName="storiesInput"
              textErrors={textErrors}
              fieldErrors={fieldErrors}
              onChange={handleStoriesChange}
              onBlur={handleBlur}
            />

            <FormField
              id="durationInput"
              value={duration}
              placeholder={formatMessage(translations.durationHoursPlaceholder)}
              error={textErrors.duration}
              label={formatMessage(translations.durationHoursPlaceholder)}
              maxLength={100}
              inputClassName="durationInput"
              textErrors={textErrors}
              fieldErrors={fieldErrors}
              onChange={handleDurationChange}
              onBlur={handleBlur}
            />

            <FormField
              id="routeInput"
              value={route}
              placeholder={formatMessage(translations.routeLengthPlaceholder)}
              error={textErrors.route}
              label={formatMessage(translations.routeLengthPlaceholder)}
              maxLength={100}
              inputClassName="routeInput"
              textErrors={textErrors}
              fieldErrors={fieldErrors}
              onChange={handleRouteChange}
              onBlur={handleBlur}
            />

            <DropdownInput
              id="textSourceMainForm"
              activeDropdownId={activeDropdownId}
              label={formatMessage(translations.TextSource)}
              value={dropdownValues.textSourceMainForm}
              options={stepTextSourceDropdownOptions}
              onToggleDropdown={handleToggleDropdown}
              onSelect={(value) =>
                handleDropdownChange('textSourceMainForm', value)
              }
            />

            <FormField
              topic
              isTextArea
              id="topicInput"
              value={topic}
              placeholder={formatMessage(translations.topicPlaceholder)}
              error={textErrors.topic}
              label={formatMessage(translations.topicPlaceholder)}
              inputClassName="GenerateAiTourForm__input GenerateAiTourForm__input_text"
              maxLength={1000}
              textErrors={textErrors}
              fieldErrors={fieldErrors}
              onChange={handleChange(setTopic)}
              onBlur={() => handleBlur('topic')}
            />
            {/* <ToggleSwitch
              label="Tour with ticket"
              subtitle="Will generate a new product card"
              isChecked={isTourChecked}
              onToggle={() => setTourChecked(!isTourChecked)}
            /> */}
            <ToggleSwitch
              disabled={!startingPoint}
              label="Tour with ticket"
              isChecked={isTourChecked}
              onToggle={() => setTourChecked(!isTourChecked)}
            />
            <Button
              className="GenerateAiTourForm__generate-button"
              size="medium"
              disabled={!areMainFieldsFilled || hasErrors}
              onClick={handleSubmitButton}
            >
              <StarIcon />
              {formatMessage(translations.generateButton)}
            </Button>
            {showChangesPopup && (
              <SpinnerPopup
                showAlert
                formatMessage={formatMessage}
                isOpen={showChangesPopup}
                handleCloseClick={() => setShowChangesPopup(false)}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        </div>
      )}

      <ToggleButton
        id="Steps"
        label={formatMessage(translations.stepsLabel)}
        isGenerated={generationProgress === 100}
        isOpen={blocksVisibility.Steps}
        toggleVisibility={toggleBlockVisibility}
        isLocked={generationProgress !== 100}
      />

      {blocksVisibility.Steps && (
        <div className="GenerateAiTourForm__block">
          {!checkTaskStatusError ? (
            <ImportantNote
              title={<div>{formatMessage(translations.createStopsTitle)}</div>}
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.createStopsDescription)}
                </div>,
              ]}
              theme="helper"
            />
          ) : (
            <ImportantNote
              title={
                <span style={{ display: 'block' }}>
                  {' '}
                  {formatMessage(translations.helperErrorTitle)}
                </span>
              }
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.helperErrorDiscription)}
                </div>,
              ]}
              theme="helper-error"
            />
          )}
          <SpinnerPopup
            formatMessage={formatMessage}
            isOpen={isPopupVisible}
            handleCloseClick={() => setIsPopupVisible(false)}
          />

          <DropdownInput
            id="textSourceStops"
            activeDropdownId={activeDropdownId}
            label={formatMessage(translations.TextSource)}
            value={dropdownValues.textSourceStops}
            options={stepTextSourceDropdownOptions}
            onToggleDropdown={handleToggleDropdown}
            onSelect={(value) => handleDropdownChange('textSourceStops', value)}
          />

          <Button
            className="GenerateAiTourForm__generate-button"
            size="medium"
            disabled={!areMainFieldsFilled}
            onClick={showPopup}
          >
            <StarIcon />
            {formatMessage(translations.generateButton)}
          </Button>
          <SpinnerPopup
            showAlert
            formatMessage={formatMessage}
            isOpen={isPopupOpen}
            handleCloseClick={() => setIsPopupOpen(false)}
            handleSubmit={() =>
              handleActionClick('regenerateStops', {
                textSource: dropdownValues.textSourceStops.toLowerCase(),
              })
            }
          />
        </div>
      )}

      <ToggleButton
        id="Stories"
        label={formatMessage(translations.storiesLabel)}
        isGenerated={generationProgress === 100}
        isOpen={blocksVisibility.Stories}
        toggleVisibility={toggleBlockVisibility}
        isLocked={generationProgress !== 100}
      />

      {blocksVisibility.Stories && (
        <div className="GenerateAiTourForm__block">
          {!checkTaskStatusError ? (
            <ImportantNote
              title={
                <div>{formatMessage(translations.createStoriesTitle)}</div>
              }
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.createStoriesDescription)}
                </div>,
              ]}
              theme="helper"
            />
          ) : (
            <ImportantNote
              title={
                <span style={{ display: 'block' }}>
                  {' '}
                  {formatMessage(translations.helperErrorTitle)}
                </span>
              }
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.helperErrorDiscription)}
                </div>,
              ]}
              theme="helper-error"
            />
          )}
          <SpinnerPopup
            formatMessage={formatMessage}
            isOpen={isPopupVisible}
            handleCloseClick={() => setIsPopupVisible(false)}
          />

          <DropdownInput
            id="textSourceStories"
            activeDropdownId={activeDropdownId}
            label={formatMessage(translations.TextSource)}
            value={dropdownValues.textSourceStories}
            options={stepTextSourceDropdownOptions}
            onToggleDropdown={handleToggleDropdown}
            onSelect={(value) =>
              handleDropdownChange('textSourceStories', value)
            }
          />

          <Button
            className="GenerateAiTourForm__generate-button"
            size="medium"
            disabled={!areMainFieldsFilled}
            onClick={showPopup}
          >
            <StarIcon />
            {formatMessage(translations.generateButton)}
          </Button>
          <SpinnerPopup
            showAlert
            formatMessage={formatMessage}
            isOpen={isPopupOpen}
            handleCloseClick={() => setIsPopupOpen(false)}
            handleSubmit={() =>
              handleActionClick('regenerateStories', {
                textSource: dropdownValues.textSourceStories.toLowerCase(),
              })
            }
          />
        </div>
      )}

      <ToggleButton
        id="StepTexts"
        label={formatMessage(translations.stepTextsLabel)}
        isGenerated={generationProgress === 100}
        isOpen={blocksVisibility.StepTexts}
        toggleVisibility={toggleBlockVisibility}
        isLocked={generationProgress !== 100}
      />

      {blocksVisibility.StepTexts && (
        <div className="GenerateAiTourForm__block">
          {!checkTaskStatusError ? (
            <ImportantNote
              title={<div> {formatMessage(translations.createTextTitle)}</div>}
              items={[
                <div key={uuid()}>
                  {stepTextOption === 'allSteps'
                    ? formatMessage(translations.createTextDescription)
                    : formatMessage(translations.createTextDescriptionSeparate)}
                </div>,
              ]}
              theme="helper"
            />
          ) : (
            <ImportantNote
              title={
                <span style={{ display: 'block' }}>
                  {' '}
                  {formatMessage(translations.helperErrorTitle)}
                </span>
              }
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.helperErrorDiscription)}
                </div>,
              ]}
              theme="helper-error"
            />
          )}

          <div onChange={(event) => setStepTextOption(event.target.value)}>
            <RadioButton
              label={formatMessage(translations.allSteps)}
              value="allSteps"
              checked={stepTextOption === 'allSteps'}
              onChange={() => setStepTextOption('allSteps')}
            />
            <RadioButton
              label={formatMessage(translations.separateSteps)}
              value="separateSteps"
              checked={stepTextOption === 'separateSteps'}
              onChange={() => setStepTextOption('separateSteps')}
            />
          </div>

          {stepTextOption === 'separateSteps' && (
            <div className="GenerateAiTourForm__separateSteps">
              <div style={{ position: 'relative' }}>
                <div
                  className={`GenerateAiTourForm__inputContainer ${
                    React.isValidElement(textErrors['steps']) &&
                    textErrors['steps'].props.children
                      ? 'GenerateAiTourForm__inputContainer_error'
                      : ''
                  }`}
                >
                  <input
                    placeholder=" "
                    className={getInputClasses(
                      'stepsInput',
                      steps || isInputActive('steps', inputValues),
                      textErrors,
                      fieldErrors
                    )}
                    value={steps}
                    onChange={handleStepsChange}
                    onBlur={() => handleBlur('steps', steps, totalSteps)}
                  />
                  <label
                    htmlFor="stepsInput"
                    className="GenerateAiTourForm__label"
                  >
                    {formatMessage(translations.stepNumberPlaceholder)}{' '}
                  </label>
                  {textErrors.steps}
                </div>
              </div>

              <DropdownInput
                id="stepText"
                activeDropdownId={activeDropdownId}
                label={formatMessage(translations.otherTextsPlaceholder)}
                value={stepText}
                options={stepTextDropdownOptions}
                onToggleDropdown={handleToggleDropdown}
                onSelect={setStepText}
              />
              <div className="GenerateAiTourForm__helper">
                {formatMessage(translations.stepTextHelper)}{' '}
              </div>
              {!(stepText === 'Navigation' || stepText === 'Навигация') && (
                <>
                  {isTextConfirmed && (
                    <div className="GenerateAiTourForm__inputContainer">
                      <textarea
                        disabled
                        className="GenerateAiTourForm__input GenerateAiTourForm__input_text GenerateAiTourForm__input_active"
                        value={confirmedText.substring(0, 1000)}
                        style={{
                          width: '100%',
                          height: '63px',
                          resize: 'none',
                          overflow: 'hidden',
                          padding: '13px',
                          lineHeight: '23px',
                        }}
                      />
                    </div>
                  )}
                  <button
                    className="GenerateAiTourForm__addButton"
                    onClick={handleOpenTextPopup}
                  >
                    <AddTextIcon />
                    {isTextConfirmed
                      ? formatMessage(translations.ChangeSource)
                      : formatMessage(translations.AddSource)}
                  </button>
                  <Popup
                    theme="info"
                    title="Show more"
                    isOpen={isTextPopupOpen}
                    handleCloseClick={handleCloseTextPopup}
                    className="GenerateAiTourForm__textPopup"
                  >
                    <div className="GenerateAiTourForm__textPopup_header">
                      <div>{formatMessage(translations.AddSource)}</div>
                      <button
                        className="GenerateAiTourForm__textPopup_closeButton"
                        onClick={handleCloseTextPopup}
                      >
                        ×
                      </button>
                    </div>
                    <div className="GenerateAiTourForm__textPopup_content">
                      <textarea
                        value={textInputValue}
                        placeholder={formatMessage(translations.EnterTextHere)}
                        className="GenerateAiTourForm__textPopup_textarea"
                        maxLength={10000}
                        style={{
                          width: '100',
                          height: '663px',
                          borderRadius: '8px',
                        }}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="GenerateAiTourForm__textPopup_footer">
                      <Button
                        size="medium"
                        className="GenerateAiTourForm__generate-button GenerateAiTourForm__textPopup_button"
                        disabled={!textInputValue}
                        onClick={handleConfirm}
                      >
                        {formatMessage(translations.ConfirmButton)}
                      </Button>
                    </div>
                  </Popup>
                  <Popup
                    theme="warning"
                    title="Confirm the choice?"
                    isOpen={isConfirmPopupOpen}
                    handleCloseClick={handleConfirmClose}
                    className="GenerateAiTourForm__confirmPopup"
                  >
                    <div className="GenerateAiTourForm__confirmPopup_content">
                      <div className="GenerateAiTourForm__confirmPopup_text">
                        <div className="GenerateAiTourForm__confirmPopup_title">
                          {formatMessage(translations.ConfirmTheChoice)}
                        </div>
                        <div className="GenerateAiTourForm__confirmPopup_subtitle">
                          {formatMessage(
                            translations.ChangesMadeWillNotBeSaved
                          )}
                        </div>
                      </div>
                      <div className="GenerateAiTourForm__confirmPopup_footer">
                        <Button
                          theme="gray-light"
                          size="medium"
                          onClick={handleCancelClose}
                        >
                          {formatMessage(translations.cancelButton)}
                        </Button>
                        <Button
                          className="GenerateAiTourForm__generate-button GenerateAiTourForm__confirmPopup_button"
                          size="medium"
                          onClick={handleConfirmClose}
                        >
                          {formatMessage(translations.ConfirmButton)}
                        </Button>
                      </div>
                    </div>
                  </Popup>
                </>
              )}
            </div>
          )}
          <SpinnerPopup
            formatMessage={formatMessage}
            isOpen={isPopupVisible}
            handleCloseClick={() => setIsPopupVisible(false)}
          />

          <DropdownInput
            id="TextSource"
            activeDropdownId={activeDropdownId}
            label={formatMessage(translations.TextSource)}
            value={dropdownValues.textSourceStepTexts}
            options={stepTextSourceDropdownOptions}
            onToggleDropdown={handleToggleDropdown}
            onSelect={(value) =>
              handleDropdownChange('textSourceStepTexts', value)
            }
          />

          <Button
            className="GenerateAiTourForm__generate-button"
            size="medium"
            disabled={
              !areMainFieldsFilled ||
              (stepTextOption === 'separateSteps' && (!stepText || !steps))
            }
            onClick={() => {
              const changeType =
                stepText === 'Navigation' || stepText === 'Навигация'
                  ? 'navigation'
                  : 'text';
              const stepNumber = steps ? parseInt(steps, 10) : 0;
              const customPrompt = confirmedText || null;
              {
                stepTextOption === 'allSteps'
                  ? showPopup()
                  : handleActionClick('regenerateStepText', {
                      stepNumber,
                      changeType,
                      customPrompt,
                      textSource: dropdownValues.textSourceStepTexts.toLowerCase(),
                    });
              }
            }}
          >
            <StarIcon />
            {formatMessage(translations.generateButton)}
          </Button>
          <SpinnerPopup
            showAlert
            formatMessage={formatMessage}
            isOpen={isPopupOpen}
            handleCloseClick={() => setIsPopupOpen(false)}
            handleSubmit={() =>
              handleActionClick('regenerateSteps', {
                textSource: dropdownValues.textSourceStepTexts.toLowerCase(),
              })
            }
          />
        </div>
      )}

      <ToggleButton
        id="StepPhotos"
        label={formatMessage(translations.stepPhotosLabel)}
        isGenerated={generationProgress === 100}
        isOpen={blocksVisibility.StepPhotos}
        toggleVisibility={toggleBlockVisibility}
        isLocked={generationProgress !== 100}
      />

      {blocksVisibility.StepPhotos && (
        <div className="GenerateAiTourForm__block">
          {!checkTaskStatusError ? (
            <ImportantNote
              title={
                <div> {formatMessage(translations.createPhotosTitle)}</div>
              }
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.createPhotosDescription)}
                </div>,
              ]}
              theme="helper"
            />
          ) : (
            <ImportantNote
              title={
                <span style={{ display: 'block' }}>
                  {' '}
                  {formatMessage(translations.helperErrorTitle)}
                </span>
              }
              items={[<div key={uuid()}>{responseErrorMessage}</div>]}
              theme="helper-error"
            />
          )}

          <DropdownInput
            id="PhotoSource"
            activeDropdownId={activeDropdownId}
            label={formatMessage(translations.PhotoSource)}
            value={stepPhotosSourse}
            options={stepPhotosSourseDropdownOptions}
            onToggleDropdown={handleToggleDropdown}
            onSelect={setStepPhotosSourse}
          />

          <div onChange={(event) => setStepPhotosOption(event.target.value)}>
            <RadioButton
              label={formatMessage(translations.BasedStopName)}
              value="BasedStopName"
              checked={stepPhotosOption === 'BasedStopName'}
              onChange={() => setStepPhotosOption('BasedStopName')}
            />
            <RadioButton
              label={formatMessage(translations.BasedStopCoordinates)}
              value="separateSteps"
              checked={stepPhotosOption === 'separateSteps'}
              onChange={() => setStepPhotosOption('separateSteps')}
            />
          </div>

          <FormField
            id="photosInput"
            value={photos}
            placeholder=" "
            error={textErrors.photos}
            label={formatMessage(translations.stepNumberPlaceholder)}
            inputClassName="photosInput"
            maxLength={totalSteps}
            textErrors={textErrors}
            fieldErrors={fieldErrors}
            onChange={handlePhotosChange}
            onBlur={handleBlur}
          />
          <SpinnerPopup
            formatMessage={formatMessage}
            isOpen={isPopupVisible}
            handleCloseClick={() => setIsPopupVisible(false)}
          />
          <Button
            className="GenerateAiTourForm__generate-button"
            size="medium"
            disabled={
              !areMainFieldsFilled ||
              !(
                (stepPhotosSourse === 'Google' ||
                  stepPhotosSourse === 'Tripadvisor' ||
                  stepPhotosSourse === 'Unsplash photo') &&
                photos
              )
            }
            onClick={() => {
              let searchType = '';
              let stepNumberPhotos = photos || '';
              let sourceType = '';

              if (stepPhotosOption === 'BasedStopName' && stepNumberPhotos) {
                searchType = 'address';
              } else if (
                stepPhotosOption === 'separateSteps' &&
                stepNumberPhotos
              ) {
                searchType = 'coordinates';
              }

              if (stepPhotosSourse === 'Google' && stepNumberPhotos) {
                sourceType = 'google';
              } else if (
                stepPhotosSourse === 'Tripadvisor' &&
                stepNumberPhotos
              ) {
                sourceType = 'tripadvisor';
              } else if (
                stepPhotosSourse === 'Unsplash photo' &&
                stepNumberPhotos
              ) {
                sourceType = 'unsplash';
              }

              if (stepPhotosOption === 'BasedStopName') {
                handleActionClick('regenerateStepPhotos', {
                  stepNumberPhotos,
                  sourceType,
                  searchType: 'address',
                });
              } else {
                handleActionClick('regenerateStepPhotos', {
                  stepNumberPhotos,
                  sourceType,
                  searchType: searchType || 'address',
                });
              }
            }}
          >
            <StarIcon />
            {formatMessage(translations.generateButton)}
          </Button>
        </div>
      )}

      <ToggleButton
        id="OtherTexts"
        label={formatMessage(translations.otherTextsLabel)}
        isGenerated={generationProgress === 100}
        isOpen={blocksVisibility.OtherTexts}
        toggleVisibility={toggleBlockVisibility}
        isLocked={generationProgress !== 100}
      />

      {blocksVisibility.OtherTexts && (
        <div className="GenerateAiTourForm__block">
          {!checkTaskStatusError ? (
            <ImportantNote
              title={
                <div> {formatMessage(translations.additionalTextTitle)}</div>
              }
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.additionalTextDescription)}
                </div>,
              ]}
              theme="helper"
            />
          ) : (
            <ImportantNote
              title={
                <span style={{ display: 'block' }}>
                  {' '}
                  {formatMessage(translations.helperErrorTitle)}
                </span>
              }
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.helperErrorDiscription)}
                </div>,
              ]}
              theme="helper-error"
            />
          )}
          <DropdownInput
            id="otherTexts"
            activeDropdownId={activeDropdownId}
            label={formatMessage(translations.otherTextsPlaceholder)}
            value={otherTexts}
            options={otherTextsDropdownOptions}
            onToggleDropdown={handleToggleDropdown}
            onSelect={setOtherTexts}
          />
          <SpinnerPopup
            formatMessage={formatMessage}
            isOpen={isPopupVisible}
            handleCloseClick={() => setIsPopupVisible(false)}
          />

          <DropdownInput
            id="textSourceOtherTexts"
            activeDropdownId={activeDropdownId}
            label={formatMessage(translations.TextSource)}
            value={dropdownValues.textSourceOtherTexts}
            options={stepTextSourceDropdownOptions}
            onToggleDropdown={handleToggleDropdown}
            onSelect={(value) =>
              handleDropdownChange('textSourceOtherTexts', value)
            }
          />

          <Button
            className="GenerateAiTourForm__generate-button"
            size="medium"
            disabled={!areMainFieldsFilled || !otherTexts}
            onClick={() =>
              handleActionClick(
                otherTexts ===
                  formatMessage(translations.otherTextOptionAllOtherText)
                  ? 'regenerateOtherTexts'
                  : otherTexts ===
                    formatMessage(translations.otherTextOptionIntroduction)
                  ? 'regenerateIntroduction'
                  : otherTexts ===
                    formatMessage(translations.otherTextOptionConclusion)
                  ? 'regenerateConclusion'
                  : otherTexts ===
                    formatMessage(translations.otherTextOptionHighlights)
                  ? 'regenerateHighlights'
                  : otherTexts ===
                    formatMessage(translations.otherTextOptionOverview)
                  ? 'regenerateOverview'
                  : otherTexts ===
                    formatMessage(translations.otherTextOptionTitle)
                  ? 'regenerateTitle'
                  : otherTexts ===
                    formatMessage(translations.otherTextOptionDescription)
                  ? 'regenerateDescription'
                  : '',
                {
                  textSource: dropdownValues.textSourceOtherTexts.toLowerCase(),
                }
              )
            }
          >
            <StarIcon />
            {formatMessage(translations.generateButton)}
          </Button>
        </div>
      )}

      <ToggleButton
        id="Audio"
        label={formatMessage(translations.stepAudioLabel)}
        isGenerated={generationProgress === 100}
        isOpen={blocksVisibility.Audio}
        toggleVisibility={toggleBlockVisibility}
        isLocked={generationProgress !== 100}
      />

      {blocksVisibility.Audio && (
        <div className="GenerateAiTourForm__block">
          {!checkTaskStatusError ? (
            <ImportantNote
              title={<div>{formatMessage(translations.createAudioTitle)}</div>}
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.createAudioDescription)}
                </div>,
              ]}
              theme="helper"
            />
          ) : (
            <ImportantNote
              title={
                <span style={{ display: 'block' }}>
                  {' '}
                  {formatMessage(translations.helperErrorTitle)}
                </span>
              }
              items={[
                <div key={uuid()}>
                  {formatMessage(translations.helperErrorDiscription)}
                </div>,
              ]}
              theme="helper-error"
            />
          )}
          {(!(selectedStepAudio && stepAudioOption === 'individualSteps') ||
            stepAudioOption === 'allSteps') && (
            <DropdownInput
              id="audioSource"
              label={formatMessage(translations.AudioSource)}
              value={audioSource}
              options={stepAudioSourseDropdownOptions}
              activeDropdownId={activeDropdownId}
              onSelect={setAudioSource}
              onToggleDropdown={handleToggleDropdown}
            />
          )}

          <div onChange={(event) => setStepAudioOption(event.target.value)}>
            <RadioButton
              label={formatMessage(translations.allSteps)}
              value="allSteps"
              checked={stepAudioOption === 'allSteps'}
              onChange={() => setStepAudioOption('allSteps')}
            />
            <RadioButton
              individualSteps
              label={formatMessage(translations.individualSteps)}
              value="individualSteps"
              checked={stepAudioOption === 'individualSteps'}
              onChange={() => setStepAudioOption('individualSteps')}
            />
          </div>

          {stepAudioOption === 'individualSteps' && (
            <FormField
              id="individStepsAudioInput"
              value={individStepsAudio}
              placeholder=" "
              error={textErrors.individStepsAudio}
              label={formatMessage(translations.stepNumberPlaceholder)}
              maxLength={totalSteps}
              inputClassName="individStepsAudioInput"
              textErrors={textErrors}
              fieldErrors={fieldErrors}
              onChange={handleIndividStepsAudioChange}
              onBlur={() =>
                handleBlur('individStepsAudio', individStepsAudio, totalSteps)
              }
            />
          )}

          <div ref={stepAudioRef} style={{ position: 'relative' }}>
            <DropdownInput
              id="selectedStepAudio"
              label={formatMessage(translations.voiceActing)}
              value={voiceSearchQuery || stepAudio}
              options={voices.filter((voice) =>
                voice.toLowerCase().includes(voiceSearchQuery.toLowerCase())
              )}
              disabledOption={
                stepAudioOption === 'individualSteps' ? selectedStepAudio : null
              }
              activeDropdownId={activeDropdownId}
              onSelect={(selectedVoice) => {
                setStepAudio(selectedVoice);
                setVoiceSearchQuery('');
                handleStepAudioChange(selectedVoice);
              }}
              onToggleDropdown={handleToggleDropdown}
            />
          </div>
          <a
            href={`${TEXT_TO_SPEACH_GOOGLE}`}
            className="GenerateAiTourForm__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage(translations.previewOfTheVoice)}{' '}
          </a>

          <SpinnerPopup
            formatMessage={formatMessage}
            isOpen={isPopupVisible}
            handleCloseClick={() => setIsPopupVisible(false)}
          />
          <Button
            className="GenerateAiTourForm__generate-button"
            size="medium"
            disabled={
              // !areMainFieldsFilled ||
              (stepAudioOption === 'allSteps' && !stepAudio) ||
              (stepAudioOption === 'individualSteps' &&
                (hasErrors || !stepAudio || !individStepsAudio))
            }
            onClick={() => {
              const actionParams = {
                voiceId: stepAudio,
                audioSource,
                ...(individStepsAudio && {
                  stepNumberVoice: individStepsAudio,
                }),
              };

              handleActionClick('generateVoice', actionParams);
            }}
          >
            <StarIcon />
            {formatMessage(translations.generateButton)}
          </Button>
        </div>
      )}

      <ToggleButton
        isLocked
        id="Music"
        label={formatMessage(translations.musicLabel)}
        isGenerated={false}
        isOpen={blocksVisibility.Music}
        toggleVisibility={toggleBlockVisibility}
      />

      {blocksVisibility.Music && (
        <div className="GenerateAiTourForm__block">
          <ImportantNote
            title={<div> {formatMessage(translations.musicTitle)}</div>}
            items={[
              <div key={uuid()}>
                {formatMessage(translations.musicDescription)}
              </div>,
            ]}
            theme="helper"
          />
          <Button
            className="GenerateAiTourForm__generate-button"
            size="medium"
            disabled={!areMainFieldsFilled}
            onClick={() => setIsGenerated(true)}
          >
            <StarIcon />
            {formatMessage(translations.generateButton)}
          </Button>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  triggerUpdateQuestData: (id) => dispatch(triggerUpdateQuestData(id)),
  fetchUpdatedQuest: (id) => dispatch(fetchUpdatedQuest(id)),
});

export default connect(null, mapDispatchToProps)(GenerateAiTourForm);
